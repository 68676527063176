import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { ICancelReason } from '@/models/cancel-reason-model';
import { useItemDrawer } from '@/contexts/drawer';

import CancelReasonForm from '../components/cancel-reason-form';

interface IUpdateCancelReason {
  cancelReason: ICancelReason;
}

const UpdateCancelReasonForm: React.FC<IUpdateCancelReason> = ({
  cancelReason,
}) => {
  const form = useForm({
    defaultValues: cancelReason,
  });

  const { enqueueSnackbar } = useSnackbar();

  const drawerItem = useItemDrawer();

  const updateCancelReasonSubmit = useCallback(
    async (values: any) => {
      const { status, data } = await Http.put(
        `cancel-reasons/${cancelReason._id}`,
        values
      );

      if (status === 200) {
        enqueueSnackbar('Motivo de cancelamento atualizado com sucesso!', {
          variant: 'success',
        });
        drawerItem.close(data);
      } else
        enqueueSnackbar(
          'Ocorreu um erro ao atualizar o motivo de cancelamento!',
          {
            variant: 'error',
          }
        );
    },
    [drawerItem, cancelReason._id, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          ATUALIZAR MOVITO DE CANCELAMENTO
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={updateCancelReasonSubmit}
        >
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <CancelReasonForm />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Atualizar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default UpdateCancelReasonForm;
