import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import {
  Box,
  Button,
  Collapse,
  FormLabel,
  Grid,
  Paper,
  Stack,
} from '@mui/material';
import { ArrowRightAltRounded, Home } from '@mui/icons-material';
import { getStatusRif } from '@/structs/status-rifs';
import { getComplexities } from '@/structs/complexities';
import Http from '@/services/http';
import { IService } from '@/models/service-model';
import { IRif } from '@/models/rif-model';
import Loading from '@/components/loading';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';

const ServiceDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [service, setService] = useState<IService | undefined>();
  const hasAircraftModel = useMemo(
    () =>
      Boolean(
        service?.aircraft?._model?.model &&
          service?.aircraft?._model?.manufacturer
      ),
    [service?.aircraft?._model?.model, service?.aircraft?._model?.manufacturer]
  );

  const gotToRifReport = (rifId: string | undefined) => {
    navigate(`/rif/management/${rifId}`);
  };

  const getRifLinkComponent = (rif: IRif | undefined) => (
    <div>
      <Button disabled={!rif} onClick={() => gotToRifReport(rif?._id)}>
        Relatório RIF <ArrowRightAltRounded />
      </Button>
    </div>
  );

  const fetchService = useCallback(async () => {
    const { status, data } = await Http.get(`services/${id}`);

    if (status === 200) {
      setService(data);
    } else {
      navigate('../');
    }
  }, [navigate, id]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchService()]).finally(() => {
      setLoading(false);
    });
  }, [fetchService]);

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: `Serviços`,
            link: `/service/${service?.type.toLowerCase()}`,
          },
        ]}
        title={service?.inspection || 'Serviço'}
      />
      <Paper sx={{ overflow: 'hidden' }}>
        <Loading loading={loading} />
        <Collapse in={!loading} unmountOnExit>
          <Box sx={{ m: 3 }}>
            <Stack direction="column" spacing={3}>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Dados do serviço</strong>
                </FormLabel>
                <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                  <Grid container>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Registro',
                            value: service?.register ? service?.register : null,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Aeronave',
                            value: hasAircraftModel
                              ? `${service?.aircraft?.prefix} (${service?.aircraft?._model?.manufacturer} - ${service?.aircraft?._model?.model})`
                              : service?.aircraft?.prefix,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Base',
                            value: service?.base?.name,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Inspeção',
                            value: service?.inspection,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Complexidade',
                            value: getComplexities(
                              String(
                                service?.complexity ?? ''
                              ).toLocaleLowerCase()
                            )?.label,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <InfoLabel
                        items={[
                          {
                            title: 'Previsão de Execução',
                            value: service?.executePrediction
                              ? format(
                                  new Date(service?.executePrediction),
                                  'dd/MM/yyyy HH:mm'
                                )
                              : null,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>R.I.F.</strong>
                </FormLabel>
                <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                  <Grid container>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Código R.I.F.',
                            value: service?.rif?.code,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Observador',
                            value: service?.rif?.auditor?.name,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Status',
                            value: getStatusRif(service?.rif?.status).label,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Início',
                            value:
                              service?.rif?.start_date &&
                              format(
                                parseISO(service?.rif?.start_date as string),
                                'dd/MM/yyyy HH:mm:ss'
                              ),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: '',
                            value: getRifLinkComponent(service?.rif),
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Stack>
          </Box>
        </Collapse>
      </Paper>
    </Stack>
  );
};

export default ServiceDetails;
