import { Route, Routes } from 'react-router-dom';
import React from 'react';

import PowerBI from './elements/power-bi';

const Analytics: React.FC = () => (
  <Routes>
    <Route element={<PowerBI />} path="power_bi" />
  </Routes>
);
export default Analytics;
