import theme from '@/styles';

export const serviceStatus = [
  {
    id: 'Importado',
    label: 'Importado',
    color: theme.palette.info.main,
  },
  {
    id: 'Conferido',
    label: 'Conferido',
    color: theme.palette.success.main,
  },
  {
    id: 'Cancelado',
    label: 'Cancelado',
    color: theme.palette.error.main,
  },
  {
    id: 'Error',
    label: 'Contém Erros',
    color: theme.palette.warning.main,
  },
];

export const getServiceStatus = (code: string | null = 'Importado') =>
  serviceStatus.find((s) => s.id === code) ?? {
    id: code,
    label: code,
    color: theme.palette.info.main,
  };
