import { Navigate, useNavigate } from 'react-router-dom';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Grow, Paper, Stack } from '@mui/material';
import Http from '@/services/http';
import Validator from '@/libraries/validator';
import { useAuth } from '@/contexts/auth';
import logo from '@/assets/omni-black.svg';
import bgLogin from '@/assets/bg-login.jpg';

interface IForgetPassword {
  username: string;
}

const validationSchema = Validator.object().shape({
  username: Validator.string().required().label('Usuário'),
});

const ForgetPassword: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const form = useForm<IForgetPassword>({
    resolver: Validator.adapter(validationSchema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const forgetPassword = useCallback(
    async ({ username }: IForgetPassword) => {
      const response = await Http.post('auth/forget-password', {
        login: username,
      });
      if (response.status === 200) {
        enqueueSnackbar('Solicitação enviada com sucesso!', {
          variant: 'success',
        });
      } else
        enqueueSnackbar(
          'Algo deu errado enquanto solicitava um e-mail de recuperação, por favor tente novamente.',
          { variant: 'error' }
        );
    },
    [enqueueSnackbar]
  );

  const handleGoBack = useCallback(() => navigate('/auth/login'), [navigate]);

  if (auth.authenticated) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.primary.main,
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundImage: `url(${bgLogin})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          minWidth: '100vw',
          backdropFilter: 'blur(20px)',
          flexDirection: 'column',
        }}
      >
        <FormContainer
          defaultValues={{ username: undefined }}
          formContext={form}
          onSuccess={forgetPassword}
        >
          <Stack spacing={3}>
            <Grow in>
              <Paper
                elevation={24}
                sx={{
                  minWidth: { xs: '100%', md: 350 },
                  p: 4,
                }}
              >
                <Stack spacing={4}>
                  <img alt="Omni Brasil" height={50} src={logo} />
                  <TextFieldElement label="Usuário" name="username" required />
                  <Stack direction="row" spacing={2}>
                    <Button onClick={handleGoBack}>Voltar</Button>
                    <Button
                      color="secondary"
                      disableElevation
                      size="large"
                      sx={{ alignSelf: 'flex-end' }}
                      type="submit"
                      variant="contained"
                    >
                      Solicitar e-mail de recuperação
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Grow>
          </Stack>
        </FormContainer>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
