import { Route, Routes } from 'react-router-dom';

import RifPending from './elements/rif-pending';
import RifManagement from './elements/rif-management/rif-management';
import RifManagementDetail from './elements/rif-management-detail';
import RifConcluded from './elements/rif-concluded';
import RifAdmin from './elements/rif-admin/rif-admin';
import RifManagementAdminDetail from './elements/rif-admin-management/rif-admin-management';

const RIF = () => (
  <Routes>
    <Route element={<RifPending />} path="pending" />
    <Route element={<RifConcluded />} path="concluded" />
    <Route element={<RifManagement />} path="management" />
    <Route element={<RifAdmin />} path="aero2541" />
    <Route element={<RifManagementAdminDetail />} path="aero2541/:id" />
    <Route element={<RifManagementDetail />} path="management/:id" />
  </Routes>
);

export default RIF;
