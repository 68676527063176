interface IGetLengthOptions {
  uniqueEntityName?: string;
  pluralEntityName?: string;
  emptyLabel?: string;
}

export const getLengthLabel = (length: number, options?: IGetLengthOptions) =>
  length > 0
    ? `${length} ${
        length > 1
          ? options?.pluralEntityName || 'Items'
          : options?.uniqueEntityName || 'Item'
      }`
    : options?.emptyLabel || 'Empty';
