import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { IService } from '@/models/service-model';
import { useItemDrawer } from '@/contexts/drawer';
import { useDialog } from '@/contexts/dialog';

import ServiceSupportForm from '../../components/service-support-form';
import ServiceMNTForm from '../../components/service-mnt-form';

interface IUpdateService {
  service: Omit<IService, 'aircraft' | 'base'> & {
    aircraft?: string;
    base?: string;
  };
  type: 'maintenance' | 'support';
}

const formMap = {
  maintenance: ServiceMNTForm,
  support: ServiceSupportForm,
};

const UpdateService: React.FC<IUpdateService> = ({ service, type }) => {
  const form = useForm({
    defaultValues: service,
  });

  const drawerItem = useItemDrawer();
  const dialog = useDialog();

  const { enqueueSnackbar } = useSnackbar();

  const updateServiceSubmit = useCallback(
    async (values: any) => {
      const { data, status } = await Http.put(
        `/services/${service?._id}`,
        values
      );

      if (status === 200) {
        enqueueSnackbar('Serviço atualizado com sucesso!', {
          variant: 'success',
        });
        drawerItem.close(data);
      } else {
        enqueueSnackbar('Ocorreu um erro ao atualizar o serviço.', {
          variant: 'error',
        });
      }
    },
    [drawerItem, service._id, enqueueSnackbar]
  );

  const deleteService = async () => {
    dialog.open({
      title: 'Confirmação',
      message: `Deseja excluir o serviço ${service.code}?`,
      buttons: [
        {
          label: 'Não',
        },
        {
          label: 'Sim',
          options: {
            variant: 'contained',
          },
          async callback() {
            const { status } = await Http.delete(`services/${service._id}`);

            if (status === 200) {
              enqueueSnackbar('Serviço excluído com sucesso!', {
                variant: 'success',
              });
              drawerItem.close(service);
            } else
              enqueueSnackbar('Ocorreu um erro ao excluir o serviço!', {
                variant: 'error',
              });
          },
        },
      ],
    });
  };

  const Form = formMap[type];

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography
          sx={{ textTransform: 'uppercase', fontSize: 20, fontWeight: 'bold' }}
          variant="h1"
        >
          {service.code}
          {type === 'maintenance' && (
            <>
              {' '}
              - {service.register} - {service.inspection}
            </>
          )}
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={updateServiceSubmit}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Form />
          </Box>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Box>
              <Button type="submit" variant="contained">
                Atualizar
              </Button>
              <Button onClick={() => drawerItem.close()}>Cancelar</Button>
            </Box>
            <Button
              color="error"
              onClick={() => deleteService()}
              sx={{ justifySelf: 'end' }}
              variant="contained"
            >
              Excluir
            </Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default UpdateService;
