import { useSearchParams } from 'react-router-dom';
import {
  DatePickerElement,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { FormProvider, useForm } from 'react-hook-form';
import { FC, useCallback, useMemo } from 'react';

import { endOfDay, startOfDay } from 'date-fns';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { rolesList } from '@/helpers/roles';
import { getCleanValues } from '@/helpers/get-clean-values';

interface IUserManagmentFilterForm {
  onSubmit(values: Record<string, any>): Promise<void>;
}

const UserManagmentFilterForm: FC<IUserManagmentFilterForm> = ({
  onSubmit,
}) => {
  const [searchParams] = useSearchParams();
  const form = useForm({
    defaultValues: Object.fromEntries(searchParams) as Record<string, any>,
    mode: 'onSubmit',
  });

  const cleanForm = useMemo(() => getCleanValues(form.getValues()), [form]);

  const clearForm = useCallback(() => {
    form.reset(cleanForm);
    setTimeout(() => {
      form.handleSubmit(onSubmit)();
    }, 0);
  }, [form, cleanForm, onSubmit]);

  const parsedRoles = useMemo(
    () => rolesList.map((role) => ({ id: role, label: role })),
    []
  );

  return (
    <Box sx={{ p: 2, width: '80vw' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography variant="caption">Filtros</Typography>
          <Stack direction="column" spacing={2}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <RadioButtonGroup
                  label="Status"
                  name="active"
                  options={[
                    {
                      id: '',
                      label: 'Todos',
                    },
                    {
                      id: 'true',
                      label: 'Ativos',
                    },
                    {
                      id: 'false',
                      label: 'Inativos',
                    },
                  ]}
                  row
                />
              </Grid>
              <Grid item md={4}>
                <TextFieldElement label="Nome" name="name" />
              </Grid>
              <Grid item md={4}>
                <TextFieldElement label="Email" name="email" />
              </Grid>
              <Grid item md={6}>
                <TextFieldElement label="Registro" name="registration" />
              </Grid>
              <Grid item md={6}>
                <SelectElement
                  label="Cargo"
                  name="role"
                  options={parsedRoles}
                />
              </Grid>
              <Grid item md={3}>
                <DatePickerElement
                  label="Data de criação (de)"
                  name="start_createdAt"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('start_createdAt', startOfDay(value));
                    }
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <DatePickerElement
                  label="Data de criação (até)"
                  name="end_createdAt"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('end_createdAt', endOfDay(value));
                    }
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <DatePickerElement
                  label="Data de atualização (de)"
                  name="start_updatedAt"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('start_updatedAt', startOfDay(value));
                    }
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <DatePickerElement
                  label="Data de atualização (até)"
                  name="end_updatedAt"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('end_updatedAt', endOfDay(value));
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
              <Button onClick={clearForm}>Limpar</Button>
              <Button color="secondary" type="submit" variant="contained">
                Aplicar
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default UserManagmentFilterForm;
