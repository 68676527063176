import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { Home } from '@mui/icons-material';
import { rolesDescriptions } from '@/helpers/roles';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';

const roles = Object.entries(rolesDescriptions);

const ProfileList: React.FC = () => (
  <Stack spacing={2}>
    <Header
      breadcrumbs={[
        {
          icon: Home,
        },
        {
          text: 'Configurações',
        },
        {
          text: 'Perfis',
        },
      ]}
      title="Perfis"
    />
    <Paper sx={{ width: '100%' }}>
      <Stack>
        <TableContainer sx={{ overflowY: 'auto' }}>
          <Table sx={{ maxwidth: '100%', whiteSpace: 'nowrap' }}>
            <TableBody>
              {roles.map(([role, description]) => (
                <TableRow key={role}>
                  <TableCell>
                    <InfoLabel
                      items={[
                        {
                          title: 'Perfil',
                          value: role,
                        },
                      ]}
                    />
                  </TableCell>
                  <TableCell>
                    <InfoLabel
                      items={[
                        {
                          title: 'Descrição',
                          value: description,
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  </Stack>
);

export default ProfileList;
