import { Link, useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';

import { format } from 'date-fns';
import {
  Box,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrowForward, OpenInNew } from '@mui/icons-material';
import { getStatusRif } from '@/structs/status-rifs';
import { IRif } from '@/models/rif-model';
import { truncateString } from '@/helpers/truncate-string';
import InfoLabel from '@/components/Info/label';

import { parseBase } from '../utils/parseBase';

interface RifManagmentRowSupportProps {
  rif: IRif;
  admin?: boolean;
}

const RifManagmnetRowSupport: React.FC<RifManagmentRowSupportProps> = ({
  rif,
  admin,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const truncateSize = useMemo(() => {
    if (isExtraLargeScreen) return 48;
    if (isLargeScreen) return 32;
    if (isMediumScreen) return 24;
    return 12;
  }, [isExtraLargeScreen, isLargeScreen, isMediumScreen]);

  return (
    <TableRow
      key={rif._id}
      sx={{
        '& > td + td': {
          pl: 2,
          pr: 0,
        },
      }}
    >
      <TableCell sx={{ pr: 0 }}>
        <IconButton
          color="primary"
          component={Link}
          size="small"
          to={`/rif/${admin ? 'aero2541' : 'management'}/${rif._id}`}
        >
          <ArrowForward />
        </IconButton>
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 600,
        }}
      >
        <InfoLabel
          items={[
            {
              title: 'Inspeção/Serviço',
              value: (
                <Tooltip title={rif?.service?.inspection || ''}>
                  <span
                    style={{
                      width: '90%',
                    }}
                  >
                    {truncateString(rif?.service?.inspection || '', 300) ||
                      'N/A'}
                    <IconButton
                      onClick={() => navigate(`/service/${rif?.service?._id}`)}
                    >
                      <OpenInNew
                        sx={{ fontSize: 14, display: 'inline-block' }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
            },
            {
              title: 'Processo',
              value: (
                <Tooltip title={rif?.maintenance_support_processes?.join(', ')}>
                  <span>
                    {truncateString(
                      rif?.maintenance_support_processes?.join(', '),
                      truncateSize
                    ) || 'N/A'}
                  </span>
                </Tooltip>
              ),
            },
          ]}
        />
      </TableCell>
      <TableCell>
        <InfoLabel
          items={[
            {
              title: 'Tipo de serviço',
              value: 'APOIO',
            },
            {
              title: 'Base',
              value: parseBase(rif.service?.base),
            },
          ]}
        />
      </TableCell>
      <TableCell>
        <InfoLabel
          items={[
            {
              title: 'Data de realização do serviço',
              value: rif?.service_completion_date
                ? format(
                    new Date(rif?.service_completion_date),

                    'dd/MM/yyyy HH:mm'
                  )
                : null,
            },
            {
              title: 'Data de início do RIF',
              value: rif?.start_date
                ? format(new Date(rif?.start_date), 'dd/MM/yyyy HH:mm')
                : null,
            },
          ]}
        />
      </TableCell>
      <TableCell>
        <InfoLabel
          items={[
            {
              title: 'Código RIF',
              value: rif.code,
            },
            {
              title: 'Status',
              value: (
                <Box sx={{ width: '100%', pr: 2 }}>
                  <Chip
                    label={getStatusRif(rif.status)?.label}
                    size="small"
                    sx={{
                      bgcolor: getStatusRif(rif.status)?.color,
                      color: 'white',
                    }}
                  />
                </Box>
              ),
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
};

export default RifManagmnetRowSupport;
