import { Link as RouterLink, To } from 'react-router-dom';
import React, { ComponentType, Fragment, ReactNode } from 'react';

import {
  Breadcrumbs,
  Link,
  Stack,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

interface HeaderBreadcrumbIconItem {
  icon: ComponentType<SvgIconProps>;
}

interface HeaderBreadcrumbTextItem {
  text: string;
}
interface HeaderBreadcrumbLinkItem {
  link: To;
  text: string;
}

interface HeaderProps {
  title: ReactNode;
  headerChildren?: ReactNode;
  breadcrumbs?: (
    | HeaderBreadcrumbIconItem
    | HeaderBreadcrumbTextItem
    | HeaderBreadcrumbLinkItem
  )[];
}

const Header: React.FC<HeaderProps> = ({
  title,
  headerChildren,
  breadcrumbs,
}) => (
  <>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      <Typography
        color={(theme) => theme.palette.grey[800]}
        sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}
        variant="h5"
      >
        <strong>{title}</strong>
      </Typography>
      {!!headerChildren && (
        <Stack direction="row" spacing={1}>
          {headerChildren}
        </Stack>
      )}
    </Stack>
    {breadcrumbs && breadcrumbs.length > 0 && (
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        sx={{ justifyContent: 'center', mb: 2 }}
      >
        {breadcrumbs.map(({ icon: Icon, text, link }: any, key: number) => {
          if (Icon) {
            return <Icon fontSize="inherit" key={key} sx={{ mt: 1 }} />;
          }
          if (link) {
            return (
              <Link color="inherit" component={RouterLink} key={key} to={link}>
                {text}
              </Link>
            );
          }
          return <Typography key={key}>{text}</Typography>;
        })}
      </Breadcrumbs>
    )}
  </>
);
export default Header;
