import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { Attachment, CloudUpload, Home } from '@mui/icons-material';
import Http from '@/services/http';
import Header from '@/components/layouts/header';

import FilesHistory from './files-history';

const PCPUpload: React.FC = () => {
  const [file, setFile] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const form = new FormData();
    if (file.name) form.append('file', file, file.name);
    else form.append('file', file, 'programming.xlsx');
    const { status, data } = await Http.post('files/import', form);

    if (status === 201) {
      enqueueSnackbar('Relatório importado com sucesso!', {
        variant: 'success',
      });
      setTimeout(() => {
        navigate(`/service/files/${data?._id}`);
      }, 1500);
    } else
      enqueueSnackbar('Ocorreu um erro ao importar o relatório!', {
        variant: 'error',
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileLoaded = event?.target?.files && event.target.files[0];
    setFile(fileLoaded);
  };

  return (
    <Stack
      spacing={2}
      sx={{ height: '100%', bgcolor: (theme) => theme.palette.grey[200] }}
    >
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'Gestão de Serviços',
          },
          {
            text: 'Importação PCP',
          },
        ]}
        title="Importação PCP"
      />
      <Paper
        elevation={2}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          spacing={1}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            color: (theme) => theme.palette.grey[700],
          }}
        >
          <Typography textAlign="center">
            <h3>
              {file
                ? `Arquivo selecionado: ${file.name}`
                : 'Por favor, procure uma arquivo para fazer upload'}
            </h3>
            <Button
              color="secondary"
              component="label"
              startIcon={<Attachment />}
              variant="outlined"
            >
              {!file ? 'Anexar Arquivo' : 'Substituir Arquivo'}
              <input
                accept=".tsv"
                hidden
                onChange={(e) => handleChange(e)}
                type="file"
                value={file?.pictureAsFile}
              />
            </Button>
          </Typography>
          <Typography variant="caption">
            Apenas arquivos com a extensão <strong>.tsv</strong>
          </Typography>
          <Button
            disabled={!file}
            onClick={handleSubmit}
            startIcon={<CloudUpload />}
            variant="contained"
          >
            Carregar
          </Button>
        </Stack>
      </Paper>
      <FilesHistory />
    </Stack>
  );
};
export default PCPUpload;
