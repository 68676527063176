import { FC } from 'react';

import { Box, IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';

interface IRifPhotoProps {
  url?: string;
  alt?: string;
  onDownload?: () => void;
}
export const RifPhoto: FC<IRifPhotoProps> = ({ url, alt, onDownload }) => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
      borderRadius: '12px',
      overflow: 'hidden',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        bottom: 8,
        width: '100%',
        height: 'fit-content',
        opacity: 1,
        transition: 'all .4s ease',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: 2,
        }}
      >
        <IconButton
          onClick={onDownload}
          sx={{
            color: '#fff',
            marginRight: '8px',
            borderRadius: '50%',
            background: 'rgba(0, 0, 0, .8)',
            '&:hover': {
              background: 'rgba(0, 0, 0, .3)',
            },
          }}
        >
          <Download />
        </IconButton>
      </div>
    </Box>
    <img
      alt={alt}
      src={url}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      }}
    />
  </Box>
);
