import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Dictionary, groupBy, isEmpty, sortBy } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Pagination,
  Popover,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Error,
  ExpandMore,
  FilterList,
  Home,
} from '@mui/icons-material';
import Http from '@/services/http';
import { IPagination } from '@/models/pagination-model';
import { IObservedEvent } from '@/models/observed-event-model';
import { useDrawer } from '@/contexts/drawer';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';

import ObservedEventFilterForm from './components/observed-event-filter-form';
/* import UpdateObservedEventForm from '../update-observed-event'; */
import CreateObservedEventForm from '../create-observed-event';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ObservedEventList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const drawer = useDrawer();

  const [observedEvents, setObservedEvents] = useState<
    IPagination<IObservedEvent>
  >({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });
  const groupedObservedEvents: Dictionary<IObservedEvent[]> = useMemo(
    () =>
      groupBy(
        sortBy(observedEvents.docs, 'code'),
        (item) => `${item.code.charAt(0)} - ${item.category}`
      ),
    [observedEvents]
  );

  const [loadingObservedEvents, setLoadingObservedEvents] =
    useState<boolean>(false);

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          queryValue = value.toISOString();
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value._id) {
            queryValue = value._id;
          }
        }

        /* Check is value is a boolean */
        if (typeof value === 'boolean') {
          if (value) queryValue = 'true';
          else queryValue = undefined;
        }

        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      if (!values.page) {
        searchParams.delete('page');
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchObservedEvents = useCallback(async () => {
    setLoadingObservedEvents(true);

    const { status, data } = await Http.get(
      'observed-events?pagination=false',
      {
        params: {
          ...Object.fromEntries(searchParams),
          limit: 150,
        },
      }
    );

    if (status === 200) setObservedEvents(data);

    setLoadingObservedEvents(false);
  }, [searchParams]);

  const createObservedEvent = () => {
    drawer.open({
      element: <CreateObservedEventForm />,
      onClose(createdObservedEvent) {
        if (createdObservedEvent) {
          fetchObservedEvents();
        }
      },
    });
  };

  useEffect(() => {
    fetchObservedEvents();
  }, [fetchObservedEvents]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'Configurações',
          },
          {
            text: 'Eventos Observados',
          },
        ]}
        headerChildren={
          <Stack alignItems="center" direction="row" spacing={1}>
            <IconButton color="secondary" {...bindTrigger(popupState)}>
              <FilterList />
            </IconButton>
            <Popover {...bindPopover(popupState)}>
              <ObservedEventFilterForm
                onSubmit={async (values) => {
                  popupState.close();
                  submitFilter(values);
                }}
              />
            </Popover>
            <IconButton
              color="secondary"
              onClick={() => {
                createObservedEvent();
              }}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        }
        title="Eventos Observados"
      />
      <Box sx={{ width: '100%' }}>
        <Collapse in={loadingObservedEvents} unmountOnExit>
          <Box
            alignItems="center"
            bgcolor="grey.100"
            display="flex"
            justifyContent="center"
            p={4}
          >
            <CircularProgress />
          </Box>
        </Collapse>
        <Collapse
          in={!loadingObservedEvents && observedEvents.docs.length === 0}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography variant="caption">
              <Error sx={{ fontSize: '56px' }} />
            </Typography>
            <Typography color="GrayText" variant="h5">
              Nenhum evento encontrado
            </Typography>
          </Stack>
        </Collapse>
        <Collapse in={!loadingObservedEvents || observedEvents.docs.length > 0}>
          {Object.keys(groupedObservedEvents).map((categoryName) => (
            <Accordion disableGutters key={categoryName}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                  sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold' }}
                >
                  {categoryName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer sx={{ overflowY: 'auto' }}>
                  <Table sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}>
                    <TableBody>
                      {groupedObservedEvents[categoryName].map(
                        (observedEvent) => (
                          <StyledTableRow key={observedEvent._id}>
                            <TableCell sx={{ width: '60px' }}>
                              <InfoLabel
                                items={[
                                  {
                                    title: 'Código',
                                    value: observedEvent.code,
                                  },
                                ]}
                              />
                            </TableCell>
                            <TableCell>
                              <InfoLabel
                                items={[
                                  {
                                    title: 'Nome',
                                    value: observedEvent.name,
                                  },
                                ]}
                              />
                            </TableCell>
                            <TableCell>
                              <Stack alignItems="center">
                                {observedEvent.active ? (
                                  <Chip color="success" label="Ativo" />
                                ) : (
                                  <Chip label="Não ativo" />
                                )}
                              </Stack>
                            </TableCell>
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </Collapse>
        {observedEvents.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={observedEvents.totalPages}
              onChange={(event, selectedPage) =>
                submitFilter({ page: selectedPage.toString() })
              }
              page={observedEvents.offset}
            />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default ObservedEventList;
