interface IValues {
  [index: string]: any;
}

export const getCleanValues = (values: IValues) => {
  const result: IValues = {};
  Object.keys(values).forEach((key) => {
    if (typeof values[key] !== 'string') {
      result[key] = null;
    }
    result[key] = '';
  });
  return result;
};
