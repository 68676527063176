/**
 * Download a file using a URL.
 * @param url A URL to be downloaded.
 * @param suggestFileName If downloaded content does not provide a filename, this value will be used.
 */
export function downloadUrl(url: string, suggestFileName = '') {
  const el = document.createElement('a');
  el.href = url;

  const splittedUrl = url.split('/');

  el.setAttribute(
    'download',
    suggestFileName || splittedUrl[splittedUrl.length - 1]
  );

  // Append to html el element page
  document.body.appendChild(el);

  // Start download
  el.click();

  // Clean up and remove the el
  el.parentNode?.removeChild(el);
}
