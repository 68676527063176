import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Setting from './setting';
import Service from './service';
import RIF from './rif';
import Dashboard from './dashboard';
import Login from './authentication';
import Analytics from './analytics';
import { AuthLayout } from '../components/layouts';

const SecurityRoute = () => <AuthLayout />;

const Modules = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Login />} path="auth/*" />
      <Route element={<SecurityRoute />}>
        <Route element={<Dashboard />} index />
        <Route element={<Analytics />} path="analytics/*" />
        <Route element={<Service />} path="service/*" />
        <Route element={<RIF />} path="rif/*" />
        <Route element={<Setting />} path="setting/*" />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Modules;
