import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { useItemDrawer } from '@/contexts/drawer';

import ServiceSupportForm from '../../components/service-support-form';
import ServiceMNTForm from '../../components/service-mnt-form';

const formMap = {
  maintenance: ServiceMNTForm,
  support: ServiceSupportForm,
};

const titleMap = {
  maintenance: 'MNT',
  support: 'DE APOIO',
};

const capitalCase = (str: string) => str[0].toUpperCase() + str.substring(1);

const CreateServiceForm: React.FC<{ type: 'maintenance' | 'support' }> = ({
  type,
}) => {
  const form = useForm({
    defaultValues: { active: true, type: capitalCase(type) },
  });
  const drawerItem = useItemDrawer();

  const { enqueueSnackbar } = useSnackbar();

  const createServiceSubmit = useCallback(
    async (values: any) => {
      const { status, data } = await Http.post('services', values);

      if (status === 201) {
        enqueueSnackbar('Serviço criado com sucesso!', { variant: 'success' });
        drawerItem.close(data);
      } else
        enqueueSnackbar('Ocorreu um erro ao criar o serviço!', {
          variant: 'error',
        });
    },
    [drawerItem, enqueueSnackbar]
  );

  const Form = formMap[type];

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          CRIAR SERVIÇO {titleMap[type]}
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={createServiceSubmit}
        >
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <Form />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Criar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default CreateServiceForm;
