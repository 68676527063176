import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Grid, Grow, Paper, Typography } from '@mui/material';
import Http from '@/services/http';
import Loading from '@/components/loading';

import { IdentifiedWeaknessCategory } from '.';

interface ListIdentifiedWeaknessesByCategoryProps {
  category: IdentifiedWeaknessCategory;
}

type ListIdentifiedWeaknessesByCategoryState = {
  name: string;
  code: string;
  proportion: number;
};

const colorPalette = [
  '#76A5E3', // Azul Claro
  '#F4A688', // Coral
  '#8CC2BE', // Verde Água
  '#F2D880', // Amarelo Pastel
  '#D495D1', // Lavanda Acentuada
  '#E3B47A', // Pêssego
  '#89C79F', // Verde Claro
  '#F4A6AE', // Rosa Claro
  '#A5A9D6', // Azul Lavanda
  '#E69580', // Salmão Claro
  '#B5CC9E', // Verde Pastel
  '#E8A877', // Laranja Claro
  '#9AA8F2', // Azul Médio
  '#D3B796', // Bege Claro
  '#8BA4E3', // Azul Suave
  '#9FD3C2', // Verde Gelado
  '#E88988', // Rosa Acentuado
  '#C5B878', // Amarelo Escuro
];

const getTheNextColor = (index: number) => {
  if (index > colorPalette.length - 1) {
    return colorPalette[index - colorPalette.length - 1];
  }
  return colorPalette[index];
};

const formatPercentage = (value: string) => {
  const number = Number(value);
  if (Number.isNaN(number)) return '-';
  return `${number.toFixed(2)}%`;
};

function customTooltip(props: any) {
  if (props.active && props.payload && props.payload.length) {
    return (
      <Box>
        <Paper sx={{ p: 1 }}>
          <Typography variant="subtitle2">
            {props.payload[0].payload.name}
          </Typography>
          <Typography>
            Proporção: {formatPercentage(props.payload[0].value)}
          </Typography>
          <Typography>
            Total de eventos: {props.payload[0].payload?.totalEvents ?? '0'}
          </Typography>
        </Paper>
      </Box>
    );
  }

  return null;
}

export const ListIdentifiedWeaknessesByCategory: React.FC<
  ListIdentifiedWeaknessesByCategoryProps
> = ({ category }) => {
  const [categoryStats, setCategoryStats] = React.useState<
    ListIdentifiedWeaknessesByCategoryState[] | null
  >(null);
  const [loading, setLoading] = React.useState(false);

  const fetchIdentifiedWeaknessByCategory = React.useCallback(async () => {
    setLoading(true);
    const { data } = await Http.get(
      `dashboard/identified-category-weaknesses?category=${category?.category}`
    );
    setCategoryStats(
      data?.sort(
        (
          a: ListIdentifiedWeaknessesByCategoryState,
          b: ListIdentifiedWeaknessesByCategoryState
        ) => {
          if (a.proportion < b.proportion) {
            return 1;
          }
          if (a.proportion > b.proportion) {
            return -1;
          }
          return 0;
        }
      ) ?? []
    );
    setLoading(false);
  }, [category]);

  React.useEffect(() => {
    fetchIdentifiedWeaknessByCategory();
  }, [fetchIdentifiedWeaknessByCategory]);

  const shouldRenderChart = categoryStats && categoryStats?.length && !loading;

  return (
    <Grow in unmountOnExit>
      <Grid
        container
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(480px, auto))',
        }}
        sx={{ mt: 2, gap: 2, width: '100%', ml: 0 }}
      >
        <Loading backgroundColor="transparent" loading={loading} />
        {shouldRenderChart && (
          <Paper sx={{ p: 2, width: '100%', gridColumn: '1/-1' }}>
            <Typography sx={{ mb: 2, fontWeight: 'bold' }} variant="h6">
              {category.category}
            </Typography>
            <ResponsiveContainer
              maxHeight={Math.min(1200, categoryStats.length * 60)}
              width="100%"
            >
              <BarChart
                data={categoryStats}
                layout="vertical"
                margin={{
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  dataKey="proportion"
                  name="Proporção"
                  ticks={[0, 25, 50, 75, 100]}
                  type="number"
                />
                <YAxis dataKey="name" name="name" type="category" width={250} />
                <Tooltip content={customTooltip} formatter={formatPercentage} />
                <Bar
                  dataKey="proportion"
                  fill="#82ca9d"
                  name="Proporção"
                  textAnchor="end"
                >
                  <LabelList formatter={formatPercentage} position="right" />
                  {categoryStats.map((_, index) => (
                    <Cell
                      fill={getTheNextColor(index)}
                      key={`cell-${index}`}
                      stroke={getTheNextColor(index)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            {!category && !loading && (
              <span>
                Nenhum resultado para mostrar com o filtro selecionado.
              </span>
            )}
          </Paper>
        )}
      </Grid>
    </Grow>
  );
};
