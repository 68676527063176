import {
  AutocompleteElement,
  DatePickerElement,
  FormContainer,
} from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import React, { useCallback, useMemo, useState } from 'react';

import { debounce, unionBy } from 'lodash';
import { Button, Grid, Stack } from '@mui/material';
import { Download, Search } from '@mui/icons-material';
import Http from '@/services/http';
import { IUser } from '@/models/user-model';
import { getCleanValues } from '@/helpers/get-clean-values';

interface Option {
  id: string;
  label: string;
}

interface Options {
  bases: Option[];
}
interface IRifManagementFilterForm {
  defaultBases: Option[];
  defaultUsers: IUser[];
  defaultValues?: Record<string, any>;
  onSubmit(values: Record<string, any>): Promise<void>;
  loading?: boolean;
  onDownload: () => void;
}

const RifPendingFilterForm: React.FC<IRifManagementFilterForm> = ({
  defaultBases,
  defaultUsers,
  defaultValues,
  onDownload,
  onSubmit,
  loading = false,
}) => {
  const form = useForm({
    defaultValues,
  });
  const [options, setOptions] = useState<Options>({
    bases: defaultBases ?? [],
  });

  const cleanForm = useMemo(() => getCleanValues(form.getValues()), [form]);

  const clearForm = useCallback(() => {
    form.reset(cleanForm);
    setTimeout(() => {
      form.handleSubmit(onSubmit)();
    }, 0);
  }, [form, cleanForm, onSubmit]);

  const fetchBase = useCallback(async (search?: string | null) => {
    const { status, data } = await Http.get('bases', {
      params: {
        ...(search ? { search } : {}),
      },
    });

    if (status === 200) {
      setOptions((old) => ({
        ...old,
        bases: unionBy(
          [
            ...old.bases,
            ...data.docs.map((b: any) => ({
              id: b._id,
              label: `${b.name} (${b.code})`,
            })),
          ],
          ({ id }) => id
        ),
      }));
    }
  }, []);

  return (
    <FormContainer
      formContext={form}
      onSuccess={() => onSubmit(form.getValues())}
    >
      <Grid container p={2}>
        <Grid item md={6}>
          <AutocompleteElement
            label="Colaborador"
            name="colaborator"
            options={defaultUsers?.map((user: any) => ({
              id: user?._id,
              label: `${user?.registration} - ${user?.name}`,
            }))}
          />
        </Grid>
        <Grid item md={6}>
          <AutocompleteElement
            autocompleteProps={{
              ChipProps: { size: 'small' },
              noOptionsText: loading ? 'Carregando...' : 'Nenhuma opção',
              onOpen() {
                if (options.bases.length === 0) {
                  fetchBase();
                }
              },
              onInputChange: debounce((_, value, reason) => {
                if (reason === 'input') {
                  fetchBase(value);
                }
              }, 300),
            }}
            label="Base"
            matchId
            name="base"
            options={options.bases}
          />
        </Grid>
        <Grid item md={6}>
          <DatePickerElement label="A partir" name="start_date" />
        </Grid>
        <Grid item md={6}>
          <DatePickerElement label="Até" name="end_date" />
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" p={2} spacing={2}>
        <Button onClick={clearForm}>Limpar</Button>
        <Button
          color="secondary"
          onClick={onDownload}
          startIcon={<Download />}
          variant="outlined"
        >
          Baixar Excel
        </Button>
        <Button
          color="secondary"
          startIcon={<Search />}
          type="submit"
          variant="contained"
        >
          Procurar
        </Button>
      </Stack>
    </FormContainer>
  );
};

export default RifPendingFilterForm;
