import { IRif } from './rif-model';
import { IBase } from './base-model';
import { IAircraft } from './aircraft-model';

export enum ServiceStatusEnum {
  canceled = 'Cancelado',
  checked = 'Conferido',
  error = 'Error',
  imported = 'Importado',
}

export interface IService {
  _id: string;
  active: boolean;
  code: string;
  base: IBase;
  aircraft?: IAircraft;
  inspection: string;
  executePrediction: string;
  complexity: string;
  dayCredits: 100;
  hourCredits: 150;
  circleCredits: 200;
  register: string;
  programming: false;
  __v: 0;
  rif: IRif;
  type: 'Maintenance' | 'Support';
  status: ServiceStatusEnum;
  errorReason?: string;
}
