import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';

export interface DialogButtonOption {
  label: string;
  callback?: () => Promise<void>;
  options?: ButtonProps;
}

interface DialogItem {
  title: string;
  message?: string;
  element?: ReactNode;
  buttons?: DialogButtonOption[];
  options?: Omit<DialogProps, 'open'>;
  onClose?: () => void;
}

interface DialogItemQueue extends DialogItem {
  id: number;
}

interface DialogContextProperties {
  open(option: DialogItem): void;
  current?: DialogItemQueue;
}

const DialogContext = createContext<DialogContextProperties>(
  {} as DialogContextProperties
);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) throw new Error('dialog provider required');
  return context;
};

const DialogProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = ({ children }) => {
  const dialogsRef = useRef(0);
  const [queue, setQueue] = useState<DialogItemQueue[]>([]);
  const [current, setCurrent] = useState<DialogItemQueue>();

  const openDialog = useCallback((item: DialogItemQueue) => {
    setCurrent(item);
  }, []);

  const nextDialog = useCallback(() => {
    const [next] = queue;
    if (next) {
      openDialog(next);
    }
  }, [queue, openDialog]);

  const addDialog = useCallback((item: DialogItem) => {
    setQueue((prev) => {
      prev.push({
        ...item,
        buttons: item.buttons ?? [],
        id: dialogsRef.current,
      });
      dialogsRef.current += 1;
      return prev;
    });
  }, []);

  const closeDialog = useCallback((item: DialogItemQueue) => {
    if (item.onClose) item.onClose();
    setQueue((prev) => [...prev].filter((i) => i.id !== item.id));
    setCurrent(undefined);
  }, []);

  const handleOpen = useCallback(
    (item: DialogItem) => {
      addDialog(item);

      if (!current) {
        nextDialog();
      }
    },
    [addDialog, nextDialog, current]
  );

  const handlerOnClickButton = useCallback(
    async (item: DialogItemQueue, callback?: () => Promise<void>) => {
      if (callback) {
        await callback();
      }
      closeDialog(item);
    },
    [closeDialog]
  );

  const props = useMemo(
    () => ({
      open: handleOpen,
      current,
    }),
    [handleOpen, current]
  );

  useEffect(() => {
    if (!current) {
      nextDialog();
    }
  }, [current, nextDialog]);

  return (
    <DialogContext.Provider value={props}>
      <Dialog
        onClose={() => {
          if (current) closeDialog(current);
        }}
        open={!!current}
        {...current?.options}
      >
        <DialogTitle>{current?.title}</DialogTitle>
        <DialogContent>{current?.element || current?.message}</DialogContent>
        <DialogActions>
          {current?.buttons && current.buttons.length > 0 ? (
            current.buttons.map((button, key) => (
              <Button
                key={key}
                onClick={() => handlerOnClickButton(current, button.callback)}
                {...button.options}
              >
                {button.label}
              </Button>
            ))
          ) : (
            <Button
              autoFocus
              onClick={() => {
                if (current) closeDialog(current);
              }}
            >
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
