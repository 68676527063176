import React, { PropsWithChildren, ReactElement } from 'react';

import {
  ButtonProps,
  IconButtonProps,
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@mui/material';

interface PopoverProps extends Partial<MuiPopoverProps> {
  target: ReactElement<ButtonProps | IconButtonProps>;
}

const Popover: React.FC<PropsWithChildren<PopoverProps>> = ({
  children,
  target,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const button = React.cloneElement(target, { onClick: handleClick });

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {button}
      <MuiPopover
        {...props}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id={id}
        onClose={handleClose}
        open={open}
      >
        {children}
      </MuiPopover>
    </>
  );
};

export default Popover;
