import { CookiesProvider } from 'react-cookie';
import React from 'react';

import { SnackbarProvider } from 'notistack';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';

import theme from './styles';
import Modules from './modules';
import DrawerProvider from './contexts/drawer';
import DialogProvider from './contexts/dialog';
import AuthProvider from './contexts/auth';

const App: React.FC = () => (
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    <GlobalStyles
      styles={{
        body: {
          minHeight: '100vh',
        },
        html: {
          WebkitFontSmoothing: 'auto',
          minHeight: '100%',
        },
        '#root': {
          minHeight: '100vh',
        },
      }}
    />
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={5000} maxSnack={3}>
          <CookiesProvider>
            <DialogProvider>
              <DrawerProvider>
                <AuthProvider baseUrl="/" loginUrl="/auth/login">
                  <Modules />
                </AuthProvider>
              </DrawerProvider>
            </DialogProvider>
          </CookiesProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </StyledEngineProvider>
);

export default App;
