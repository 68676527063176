import { createTheme } from '@mui/material';

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#192a39',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#006195',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 5,
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: true,
        root: {
          p: 3,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 3,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            backgroundColor: 'transparent!important',
          },
        },
      },
      defaultProps: {
        fullWidth: true,
        variant: 'filled',
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: 14,
        },
        caption: {
          color: defaultTheme.palette.grey[500],
        },
        subtitle1: {
          color: defaultTheme.palette.grey[600],
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
      },
    },
  },
});

export default theme;
