import { Link, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { format, isValid } from 'date-fns';
import {
  Box,
  Chip,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { getStatusFile } from '@/structs/status-files';
import Http from '@/services/http';
import { IPagination } from '@/models/pagination-model';
import { IFile } from '@/models/file-model';
import Loading from '@/components/loading';
import InfoLabel from '@/components/Info/label';

const ITEMS_PER_PAGE = 50;

const FilesHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<IPagination<IFile>>({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          const date = format(value, 'yyyy-MM-dd');
          if (isValid(value)) {
            queryValue = date;
          }
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value.id) {
            queryValue = value.id;
          }
        }
        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchFiles = useCallback(async () => {
    setLoading(true);
    const { status, data } = await Http.get<IPagination<IFile>>('files', {
      params: {
        ...Object.fromEntries(searchParams),
        limit: ITEMS_PER_PAGE,
      },
    });
    setLoading(false);
    if (status === 200) {
      setFiles(data);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <Paper>
      <Loading loading={loading} />
      <TableContainer>
        <Table>
          <TableBody>
            {files.docs.map((file) => (
              <TableRow key={file._id}>
                <TableCell>
                  <IconButton component={Link} to={`../files/${file._id}`}>
                    <ArrowForward />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <InfoLabel
                    items={[
                      {
                        title: 'Nome',
                        value: file.archiveName,
                      },
                      {
                        title: 'Autor do envio',
                        value: file.uploadedBy.name,
                      },
                    ]}
                  />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    items={[
                      {
                        title: 'Data de envio',
                        value: format(
                          new Date(file.dateUploaded),
                          'dd/MM/yyyy HH:mm'
                        ),
                      },
                    ]}
                  />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    items={[
                      {
                        title: 'Contagem de serviços enviados',
                        value: file.servicesCount,
                      },
                    ]}
                  />
                </TableCell>
                <TableCell>
                  <InfoLabel
                    items={[
                      {
                        title: 'Status',
                        value: (
                          <Chip
                            label={getStatusFile(file.status)?.label}
                            size="small"
                            sx={{
                              bgcolor: getStatusFile(file.status)?.color,
                              color: 'white',
                              width: 'fit-content',
                              px: 2,
                              py: 1,
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {files.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={files.totalPages}
              onChange={(_, selectedPage) => {
                submitFilter({ page: selectedPage.toString() });
              }}
              page={files.offset}
            />
          </Box>
        )}
      </TableContainer>
    </Paper>
  );
};

export default FilesHistory;
