import { Link, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { isEmpty } from 'lodash';
import {
  addDays,
  endOfDay,
  format,
  isValid,
  startOfDay,
  subDays,
} from 'date-fns';
import {
  Box,
  Chip,
  IconButton,
  Pagination,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  ArrowForward,
  Edit,
  FilterList,
  Home,
} from '@mui/icons-material';
import { getStatusRif } from '@/structs/status-rifs';
import { getComplexities } from '@/structs/complexities';
import Http from '@/services/http';
import { IService } from '@/models/service-model';
import { IPagination } from '@/models/pagination-model';
import { IBase } from '@/models/base-model';
import { IAircraft } from '@/models/aircraft-model';
import { truncateString } from '@/helpers/truncate-string';
import { getLengthLabel } from '@/helpers/get-length-label';
import { useDrawer } from '@/contexts/drawer';
import Loading from '@/components/loading';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';
import FilterTags from '@/components/Info/filter-tags';

import UpdateService from '../update-service';
import CreateServiceForm from '../create-service';
import ServiceFilterForm from '../../components/service-filter-form';

interface IOption {
  id: string;
  label: string;
}

const titleMap = {
  maintenance: 'MNT',
  support: 'de Apoio',
};

const parseAircraftModel = (aircraft?: IAircraft) =>
  aircraft?._model?.manufacturer && aircraft?._model?.model
    ? `${aircraft?._model?.manufacturer} - ${aircraft?._model?.model}`
    : '-';

const cellsMap = {
  maintenance: (service: IService) => [
    [
      {
        title: 'Código',
        value: service.code,
      },
      {
        title: 'Inspeção',
        value: (
          <Tooltip title={service?.inspection}>
            <span>{truncateString(service?.inspection, 32)}</span>
          </Tooltip>
        ),
      },
    ],
    [
      {
        title: 'Aeronave',
        value: service?.aircraft?.prefix,
      },
      {
        title: 'Modelo',
        value: service?.aircraft?._id ? (
          <Tooltip title={parseAircraftModel(service?.aircraft)}>
            <span>
              {truncateString(parseAircraftModel(service?.aircraft), 32)}
            </span>
          </Tooltip>
        ) : null,
      },
    ],
    [
      {
        title: 'Base',
        value: (
          <Tooltip title={`${service.base?.name} (${service.base?.code})`}>
            <span>
              {truncateString(
                `${service.base?.name} (${service.base?.code})`,
                32
              )}
            </span>
          </Tooltip>
        ),
      },
      {
        title: 'Processos de manutenção',
        value: service?.rif?.maintenance_processes.join(', '),
      },
    ],
    [
      {
        title: 'Descrição',
        value: service.rif?.task_description,
      },
      {
        title: 'Data RIF',
        value: service?.rif?.start_date
          ? format(new Date(service?.rif?.start_date), 'dd/MM/yyyy')
          : null,
      },
    ],
    [
      {
        title: 'Tipo Rif',
        value: service?.rif?.register_type,
      },
      {
        title: 'Status Rif',
        value: (
          <Chip
            label={getStatusRif(service?.rif?.status).label}
            size="small"
            sx={{
              color: 'white',
              bgcolor: getStatusRif(service?.rif?.status).color,
            }}
          />
        ),
      },
    ],
  ],
  support: (service: IService) => [
    [
      {
        title: 'Código',
        value: service.code,
      },
      {
        title: 'Inspeção',
        value: service.inspection,
      },
    ],
    [
      {
        title: 'Base',
        value: service.base?.name,
      },
      {
        title: 'Código Base',
        value: service.base?.code,
      },
    ],
    [
      {
        title: 'Tipo Rif',
        value: service?.rif?.register_type,
      },
      {
        title: 'Status Rif',
        value: (
          <Chip
            label={getStatusRif(service?.rif?.status).label}
            size="small"
            sx={{
              color: 'white',
              bgcolor: getStatusRif(service?.rif?.status).color,
            }}
          />
        ),
      },
    ],
  ],
};

const filtersMap = {
  maintenance: { isImported: false, type: 'Maintenance' },
  support: { type: 'Support' },
};

const Services: React.FC<{ type: 'maintenance' | 'support' }> = ({ type }) => {
  const ITEMS_PER_PAGE = 50;
  const getDefaultSearchParams = (date: Date = new Date()) => {
    const searchParams = new URLSearchParams();
    const startDate = subDays(startOfDay(date), 60);
    const endDate = addDays(endOfDay(date), 90);
    searchParams.set('executePredictionStartDate', startDate.toISOString());
    searchParams.set('executePredictionEndDate', endDate.toISOString());
    return searchParams;
  };
  const defaultSearchParams = getDefaultSearchParams();

  const drawer = useDrawer();
  const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const [baseOptions, setBaseOptions] = useState<IOption[]>([]);
  const [aircraftOptions, setAircraftOptions] = useState<IOption[]>([]);

  const [services, setServices] = useState<IPagination<IService>>({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          if (isValid(value)) {
            queryValue = value.toISOString();
          }
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value.id) {
            queryValue = value.id;
          }
        }
        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchBases = useCallback(async () => {
    const { status, data } = await Http.get<IPagination<IBase>>('bases', {
      params: {
        pagination: false,
      },
    });

    if (status === 200) {
      setBaseOptions(
        data.docs.map((b: any) => ({
          id: b._id,
          label: `${b.name} (${b.code})`,
        }))
      );
    }
  }, []);

  const fetchAircrafts = useCallback(async () => {
    const { status, data } = await Http.get<IPagination<IAircraft>>(
      'aircrafts',
      {
        params: {
          pagination: false,
        },
      }
    );

    if (status === 200) {
      setAircraftOptions(
        data.docs.map((a: IAircraft) => ({
          id: a._id || '',
          label: `${a.prefix}`,
        }))
      );
    }
  }, []);

  const fetchServices = useCallback(async () => {
    const { status, data } = await Http.get<IPagination<IService>>('services', {
      params: {
        ...Object.fromEntries(searchParams),
        ...filtersMap[type],
        limit: ITEMS_PER_PAGE,
      },
    });
    if (status === 200) {
      setServices(data);
    }
  }, [searchParams, type]);

  const findBase = useCallback(
    (baseId: string) => baseOptions.find((b) => b.id === baseId),
    [baseOptions]
  );
  const findAircraft = useCallback(
    (aircraftId: string) => aircraftOptions.find((b) => b.id === aircraftId),
    [aircraftOptions]
  );

  useEffect(() => {
    setLoading(true);
    setFilterLoading(true);
    fetchServices().then(() => setLoading(false));
    Promise.all([fetchBases(), fetchAircrafts()]).then(() => {
      setFilterLoading(false);
    });
    // fetchBases().then(() => setFilterLoading(false));
  }, [fetchServices, fetchBases, fetchAircrafts]);

  const createService = () => {
    drawer.open({
      element: <CreateServiceForm type={type} />,
      onClose(newService: IService) {
        if (newService) {
          fetchServices();
        }
      },
    });
  };

  const editService = (service: IService) => () => {
    drawer.open({
      element: (
        <UpdateService
          service={{
            ...service,
            aircraft: service.aircraft?._id,
            base: service.base._id,
          }}
          type={type}
        />
      ),
      onClose(serviceUpdated: IService) {
        if (serviceUpdated) {
          fetchServices();
        }
      },
    });
  };

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: `Serviços ${titleMap[type]}`,
          },
        ]}
        headerChildren={
          <Stack alignItems="center" direction="row" spacing={1}>
            {/* <FilterTags baseOptions={baseOptions} /> */}
            <FilterTags
              defaultSearchParams={defaultSearchParams}
              tagDefs={[
                {
                  paramKey: 'code',
                  valueGetter: (code) => `Código: ${code}`,
                },
                {
                  paramKey: 'base',
                  valueGetter(baseId) {
                    return `Base: ${findBase(baseId)?.label}`;
                  },
                },
                {
                  paramKey: 'aircraft',
                  valueGetter: (aircraftId) =>
                    `Aeronave: ${findAircraft(aircraftId)?.label}`,
                },
                {
                  paramKey: 'executePredictionStartDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Previsão de Execução (De): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'executePredictionEndDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Previsão de Execução (Até): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'startRifStartDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Início do Serviço (De): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'startRifEndDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Fim do Serviço (Até): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'complexity',
                  valueGetter: (value) =>
                    `Complexidade: ${getComplexities(value)?.label}`,
                },
                {
                  paramKey: 'status',
                  valueGetter: (value) =>
                    `Status: ${getStatusRif(value)?.label}`,
                },
              ]}
            />
            <IconButton
              color="secondary"
              disabled={loading}
              {...bindTrigger(popupState)}
            >
              <FilterList />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <ServiceFilterForm
                defaultAircrafts={[]}
                defaultBases={baseOptions}
                defaultValues={{
                  colaborator: searchParams.get('colaborator'),
                  base: searchParams.get('base'),
                  executePredictionStartDate: searchParams.get(
                    'executePredictionStartDate'
                  ),
                  executePredictionEndDate: searchParams.get(
                    'executePredictionEndDate'
                  ),
                }}
                loading={filterLoading}
                onSubmit={async (values) => {
                  popupState.close();
                  submitFilter(values);
                }}
                type={type}
              />
            </Popover>
            <IconButton color="secondary" onClick={createService}>
              <AddIcon />
            </IconButton>
          </Stack>
        }
        title={`Serviços ${titleMap[type]}`}
      />
      <Box
        sx={{
          width: '100%',
          textAlign: 'right',
          padding: '0 15px',
        }}
      >
        {services.docs.length > 0 && (
          <Typography sx={{ fontSize: 14 }} variant="caption">
            {getLengthLabel(services.totalDocs, {
              pluralEntityName: 'documentos encontrados',
              uniqueEntityName: 'documento encontrado',
              emptyLabel: 'Nenhum documento encontrado',
            })}{' '}
            ({services.limit} por página)
          </Typography>
        )}
      </Box>
      <Paper>
        <Loading loading={loading} />
        <TableContainer>
          <Table>
            <TableBody>
              {services.docs.map((service) => (
                <TableRow key={service._id}>
                  <TableCell sx={{ minWidth: 116 }}>
                    <IconButton component={Link} to={`/service/${service._id}`}>
                      <ArrowForward />
                    </IconButton>
                    <IconButton onClick={editService(service)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  {cellsMap[type](service).map((items) => (
                    <TableCell>
                      <InfoLabel items={items} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {services.totalPages > 1 && (
            <Box sx={{ m: 2 }}>
              <Pagination
                count={services.totalPages}
                onChange={(_, selectedPage) => {
                  submitFilter({ page: selectedPage.toString() });
                }}
                page={services.offset}
              />
            </Box>
          )}
        </TableContainer>
      </Paper>
    </Stack>
  );
};
export default Services;
