import { Route, Routes } from 'react-router-dom';
import React from 'react';

import PCPUpload from './elements/upload';
import Services from './elements/services';
import ServiceDetails from './elements/service-detail';
import FilesDetails from './elements/files-details';

const PCP: React.FC = () => (
  <Routes>
    <Route element={<Services type="maintenance" />} path="maintenance" />
    <Route element={<Services type="support" />} path="support" />
    <Route element={<ServiceDetails />} path=":id" />
    <Route element={<PCPUpload />} path="upload" />
    <Route element={<FilesDetails />} path="files/:id" />
  </Routes>
);
export default PCP;
