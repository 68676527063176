export const OBSERVED_EVENTS_CATEGORIES = [
  'Ferramentas, Equipamento de Apoio e Materiais',
  'Informação Técnica',
  'Condições do Ambiente / Instalações',
  'Fatores Individuais',
  'Habilidade dos Executores',
  'Execução da Tarefa',
  'Comunicação',
  'Liderança',
];

export interface IObservedEvent {
  active: boolean;
  _id: string;
  name: string;
  code: string;
  // eslint-disable-next-line prettier/prettier
  category: (typeof OBSERVED_EVENTS_CATEGORIES)[any];
}
