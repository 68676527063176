import theme from '@/styles';

export const statusRifs = [
  {
    id: 'pending',
    label: 'Pendente',
    color: '#00e200',
  },
  {
    id: 'in_progress',
    label: 'Em Andamento',
    color: '#3285f1',
  },
  {
    id: 'waiting_confirmation',
    label: 'Aguardando confirmação',
    color: '#DBA800',
  },
  {
    id: 'done',
    label: 'Realizado',
    color: '#49B537',
  },
  {
    id: 'sent',
    label: 'Lançado',
    color: '#7b8089',
  },
  {
    id: 'cancelled',
    label: 'Cancelado',
    color: '#dd1100',
  },
  {
    id: 'error',
    label: 'Contém Erros',
    color: '#dd1100',
  },
];

export const getStatusRif = (code: string | null = 'pending') =>
  statusRifs.find((s) => s.id === code) ?? {
    id: code,
    label: code,
    color: theme.palette.background.default,
  };
