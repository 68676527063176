export const rolesList = [
  'ADM - TI',
  'ADM',
  'APOIO',
  'AUDITOR',
  'AVALIADOR',
  'COORDENADOR',
  'ENGENHARIA',
  'MECÂNICO',
  'PCP',
  'VISUALIZADOR',
];

export const rolesDescriptions = {
  'ADM - TI': '	Administrador do sistema (possui todas as permissões)',
  ADM: 'Administrador (possui todas as permissões - exceto adição de usuários)',
  COORDENADOR:
    'Coordenador (pode inserir e atualizar as situações das aeronaves/ver relatórios)',
  ENGENHARIA:
    'Engenharia (pode inserir e atualizar as situações das aeronaves/ver relatórios)',
  VISUALIZADOR: 'Visualizador (apenas visualização do dashboard/relatórios)',
  PCP: 'Responsável por importação de planilhas de serviços',
  'AUDITOR RIF': 'Auditor de segurança de organização de manutenção',
  /* AVALIADOR: 'Avaliador', */
  MECÂNICO: 'Mecânico',
  APOIO: 'Apoio',
};
