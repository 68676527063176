import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { IBase } from '@/models/base-model';
import { useItemDrawer } from '@/contexts/drawer';

import BaseForm from '../components/base-form';

interface IUpdateBase {
  base: IBase;
}

const UpdateBaseForm: React.FC<IUpdateBase> = ({ base }) => {
  const form = useForm({
    defaultValues: base,
  });

  const { enqueueSnackbar } = useSnackbar();

  const drawerItem = useItemDrawer();

  const updateBaseSubmit = useCallback(
    async (values: any) => {
      const { status, data } = await Http.put(`bases/${base._id}`, values);

      if (status === 200) {
        enqueueSnackbar('Base atualizada com sucesso!', { variant: 'success' });
        drawerItem.close(data);
      } else
        enqueueSnackbar('Ocorreu um erro ao atualizar a base!', {
          variant: 'error',
        });
    },
    [drawerItem, base._id, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          CRIAR BASE
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={updateBaseSubmit}
        >
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <BaseForm />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Atualizar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default UpdateBaseForm;
