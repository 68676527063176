import { useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { isEmpty } from 'lodash';
import { format, isValid } from 'date-fns';
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { FilterList, Home } from '@mui/icons-material';
import { getStatusRif } from '@/structs/status-rifs';
import { getComplexities } from '@/structs/complexities';
import Http from '@/services/http';
import { IRif } from '@/models/rif-model';
import { IPagination } from '@/models/pagination-model';
import { IBase } from '@/models/base-model';
import { IAircraft } from '@/models/aircraft-model';
import { getLengthLabel } from '@/helpers/get-length-label';
import Loading from '@/components/loading';
import Header from '@/components/layouts/header';
import FilterTags from '@/components/Info/filter-tags';

import RifManagmnetRowSupport from '../../components/rif-managment-row-support';
import RifManagmnetRowMaintenance from '../../components/rif-managment-row-maintenance';
import RifManagementFilterForm from '../../components/rif-management-filter-form';

interface IOption {
  id: string;
  label: string;
}

const ITEMS_PER_PAGE = 50;

const isMaintenance = (rif: IRif) => rif?.service?.type === 'Maintenance';

const RifManagement: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const popupState = usePopupState({ variant: 'popover', popupId: 'filter' });

  const [baseOptions, setBaseOptions] = useState<IOption[]>([]);
  const [aircraftsOptions, setAircraftsOptions] = useState<IOption[]>([]);

  const [loading, setLoading] = useState(false);

  const [filterLoading, setFilterLoading] = useState(false);

  const [rifs, setRifs] = useState<IPagination<IRif>>({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const fetchBase = useCallback(async () => {
    const { status, data } = await Http.get<IPagination<IBase>>('bases', {
      params: {
        pagination: false,
      },
    });

    if (status === 200) {
      setBaseOptions(
        data.docs.map((b: any) => ({
          id: b._id,
          label: `${b.name} (${b.code})`,
        }))
      );
    }
  }, []);

  const findBaseOption = useCallback(
    (baseId: string) => baseOptions.find((b) => b.id === baseId),
    [baseOptions]
  );

  const fetchAircrafts = useCallback(async () => {
    const { status, data } = await Http.get<IPagination<IAircraft>>(
      'aircrafts',
      {
        params: {
          pagination: false,
        },
      }
    );

    if (status === 200) {
      setAircraftsOptions(
        data.docs.map((b: any) => ({
          id: b._id,
          label: `Aeronave ${b.prefix} (${b.sn})`,
        }))
      );
    }
  }, []);

  const findAircraftOption = useCallback(
    (aircraftId: string) => aircraftsOptions.find((a) => a.id === aircraftId),
    [aircraftsOptions]
  );

  const fetchRifs = useCallback(async () => {
    const { status, data } = await Http.get<IPagination<IRif>>('rifs', {
      params: {
        ...Object.fromEntries(searchParams),
        limit: ITEMS_PER_PAGE,
      },
    });
    if (status === 200) {
      setRifs(data);
    }
  }, [searchParams]);

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          queryValue = value.toISOString();
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value.id) {
            queryValue = value.id;
          }
        }

        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    setLoading(true);
    setFilterLoading(true);
    fetchRifs().then(() => setLoading(false));
    Promise.all([fetchBase(), fetchAircrafts()]).finally(() => {
      setFilterLoading(false);
    });
  }, [fetchRifs, fetchBase, fetchAircrafts]);

  const serviceTypes = useMemo(
    () => [
      {
        id: 'Maintenance',
        label: 'Manutenção',
      },
      {
        id: 'Support',
        label: 'Apoio',
      },
    ],
    []
  );

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'RIF',
          },
          {
            text: 'Gestão',
          },
        ]}
        headerChildren={
          <Stack alignItems="center" direction="row" spacing={1}>
            <FilterTags
              tagDefs={[
                {
                  paramKey: 'code',
                  valueGetter: (value) => `Código RIF: ${value}`,
                },
                {
                  paramKey: 'base',
                  valueGetter: (baseId) =>
                    `Base: ${findBaseOption(baseId)?.label}`,
                },
                {
                  paramKey: 'aircraft',
                  valueGetter: (aircraftId) =>
                    `Aeronave: ${findAircraftOption(aircraftId)?.label}`,
                },
                {
                  paramKey: 'executePredictionStartDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Previsão de Execução (De): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'executePredictionEndDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Previsão de Execução (Até): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'startRifStartDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Início do Serviço (De): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'startRifEndDate',
                  valueGetter(value) {
                    if (!value) return '';

                    if (!isValid(new Date(value))) return '';

                    return `Fim do Serviço (Até): ${format(
                      new Date(value),
                      'dd/MM/yyyy'
                    )}`;
                  },
                },
                {
                  paramKey: 'complexity',
                  valueGetter: (value) =>
                    `Complexidade: ${getComplexities(value)?.label}`,
                },
                {
                  paramKey: 'status',
                  valueGetter: (value) =>
                    `Status: ${getStatusRif(value)?.label}`,
                },
                {
                  paramKey: 'type',
                  valueGetter: (value) =>
                    `Tipo do serviço: ${
                      serviceTypes.find((s) => s.id === value)?.label
                    }`,
                },
              ]}
            />
            <IconButton
              color="secondary"
              disabled={loading}
              {...bindTrigger(popupState)}
            >
              <FilterList />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <RifManagementFilterForm
                defaultAircrafts={aircraftsOptions}
                defaultBases={baseOptions}
                defaultValues={{
                  code: searchParams.get('code'),
                  executePredictionStartDate: searchParams.get(
                    'executePredictionStartDate'
                  ),
                  executePredictionEndDate: searchParams.get(
                    'executePredictionEndDate'
                  ),
                  serviceStartDate: searchParams.get('serviceStartDate'),
                  serviceEndDate: searchParams.get('serviceEndDate'),
                  status: searchParams.get('status'),
                  complexity: searchParams.get('complexity'),
                  base: searchParams.get('base'),
                  aircraft: searchParams.get('aircraft'),
                  type: searchParams.get('type'),
                }}
                loading={filterLoading}
                onSubmit={async (values) => {
                  popupState.close();
                  submitFilter(values);
                }}
              />
            </Popover>
          </Stack>
        }
        title="Gestão RIF"
      />
      <Box
        sx={{
          width: '100%',
          textAlign: 'right',
          padding: '0 15px',
        }}
      >
        {rifs.docs.length > 0 && (
          <Typography sx={{ fontSize: 14 }} variant="caption">
            {getLengthLabel(rifs.totalDocs, {
              pluralEntityName: 'documentos encontrados',
              uniqueEntityName: 'documento encontrado',
              emptyLabel: 'Nenhum documento encontrado',
            })}{' '}
            ({rifs.limit} por página)
          </Typography>
        )}
      </Box>
      <Paper sx={{ width: '100%' }}>
        <Loading loading={loading} />
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table
            stickyHeader
            sx={{ maxWidth: '100%' /* , whiteSpace: 'nowrap' */ }}
          >
            <TableBody>
              {rifs.docs.map((rif, i) =>
                isMaintenance(rif) ? (
                  <RifManagmnetRowMaintenance key={i} rif={rif} />
                ) : (
                  <RifManagmnetRowSupport key={i} rif={rif} />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rifs.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={rifs.totalPages}
              onChange={(_, selectedPage) => {
                submitFilter({ page: selectedPage.toString() });
              }}
              page={rifs.offset}
            />
          </Box>
        )}
      </Paper>
      {!rifs.totalDocs && !loading && (
        <Typography sx={{ pt: 2, width: '100%', textAlign: 'center' }}>
          Nenhum RIF encontrado, tente alterar alguns parâmetros da filtragem ou
          recarregar a página.
        </Typography>
      )}
    </Stack>
  );
};
export default RifManagement;
