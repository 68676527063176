/* eslint-disable no-console */
/* eslint-disable func-names */
import React from 'react';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models, service } from 'powerbi-client';
import { Stack } from '@mui/material';
import { Home } from '@mui/icons-material';
import Header from '@/components/layouts/header';

const PowerBI = () => {
  const sampleReportUrl =
    'https://playgroundbe-bck-1.azurewebsites.net/Reports/SampleReport';

  const [sampleReportConfig, setReportConfig] =
    React.useState<models.IReportEmbedConfiguration>({
      type: 'report',
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      accessToken: undefined,
      settings: undefined,
    });

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered');
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  const mockSignIn = React.useCallback(async () => {
    const reportConfigResponse = await fetch(sampleReportUrl);

    if (!reportConfigResponse.ok) {
      // eslint-disable-next-line no-console
      console.error(
        `Failed to fetch config for report. Status: ${reportConfigResponse.status} ${reportConfigResponse.statusText}`
      );
      return;
    }

    const reportConfig = await reportConfigResponse.json();
    // Set the fetched embedUrl and embedToken in the report config
    setReportConfig({
      ...sampleReportConfig,
      embedUrl: reportConfig.EmbedUrl,
      accessToken: reportConfig.EmbedToken.Token,
    });
  }, [sampleReportConfig]);

  React.useEffect(() => {
    mockSignIn();
  }, [mockSignIn]);

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
            link: '/',
          },
          {
            text: 'Análise',
          },
          {
            text: 'Power BI',
            link: '/analytics/power_bi',
          },
        ]}
        title="Power BI"
      />
      <Stack
        spacing={2}
        sx={{
          '& iframe': {
            minHeight: 600,
          },
        }}
      >
        <PowerBIEmbed
          embedConfig={sampleReportConfig}
          eventHandlers={eventHandlersMap}
        />
      </Stack>
    </Stack>
  );
};

export default PowerBI;
