import { useSearchParams } from 'react-router-dom';
import {
  SelectElement,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { IAircraftModel } from '@/models/aircraft-model-model';
import { getCleanValues } from '@/helpers/get-clean-values';

interface AircraftFilterFormProps {
  onSubmit(values: Record<string, any>): Promise<void>;
}

const AircraftFilterForm: React.FC<AircraftFilterFormProps> = ({
  onSubmit,
}) => {
  const [searchParams] = useSearchParams();

  const form = useForm({
    defaultValues: Object.fromEntries(searchParams),
  });

  const cleanForm = useMemo(() => getCleanValues(form.getValues()), [form]);

  const clearForm = useCallback(() => {
    form.reset(cleanForm);
    setTimeout(() => {
      form.handleSubmit(onSubmit)();
    }, 0);
  }, [form, cleanForm, onSubmit]);

  const [models, setModels] = useState<IAircraftModel[]>([]);
  const [loadingModels, setLoadingModels] = useState(true);

  const fetchModels = useCallback(async () => {
    const { data, status } = await Http.get('aircraft-models');

    if (status === 200) {
      setModels(data?.docs);
      setLoadingModels(false);
    }
  }, []);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  return (
    <Box sx={{ p: 2, minWidth: '50vw' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography variant="caption">Filtros</Typography>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextFieldElement
                  disabled={loadingModels}
                  label="Marca"
                  name="prefix"
                />
              </Grid>
              <Grid item xs={4}>
                <SelectElement
                  disabled={loadingModels}
                  label="Modelo"
                  name="_model"
                  options={models.map((model) => ({
                    id: model._id,
                    label: `${model.manufacturer} - ${model.model}`,
                  }))}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldElement label="Número de Série (S/N)" name="sn" />
              </Grid>
              <Grid item xs={6}>
                <SwitchElement
                  disabled={loadingModels}
                  label="Ativa"
                  name="active"
                />
              </Grid>
              <Grid item xs={6}>
                <SwitchElement
                  disabled={loadingModels}
                  label="Backup"
                  name="backup"
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
              <Button onClick={clearForm}>Limpar</Button>
              <Button color="secondary" type="submit" variant="contained">
                Aplicar
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AircraftFilterForm;
