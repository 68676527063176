import {
  AccountCircle,
  Assignment,
  AssignmentLate,
  AssignmentTurnedIn,
  BuildCircle,
  Dashboard,
  Description,
  EventNote,
  Group,
  InsertChart,
  LocalAirport,
  OtherHouses,
  Settings,
  Support,
  UploadFile,
} from '@mui/icons-material';

import { MenuItem } from '../components/auth/navigation';

const menu: MenuItem[] = [
  {
    text: 'Dashboard',
    icon: Dashboard,
    to: '/',
  },
  {
    text: 'Qualidade',
    icon: AssignmentTurnedIn,
    children: [
      {
        text: 'Gestão RIF',
        icon: Assignment,
        to: '/rif/management',
      },
      {
        text: 'RIF Realizados',
        icon: AssignmentTurnedIn,
        to: '/rif/concluded',
      },
      {
        text: 'Auditados Pendentes',
        icon: AssignmentLate,
        to: '/rif/pending',
      },
    ],
  },
  {
    text: 'Gestão de Serviços',
    icon: Description,
    children: [
      {
        text: 'Importação PCP',
        icon: UploadFile,
        to: '/service/upload',
      },
      {
        text: 'Serviços MNT',
        icon: BuildCircle,
        to: '/service/maintenance',
      },
      {
        text: 'Serviços de Apoio',
        icon: Support,
        to: '/service/support',
      },
    ],
  },
  {
    text: 'Configurações',
    icon: Settings,
    children: [
      {
        icon: AccountCircle,
        text: 'Usuários',
        to: '/setting/users',
      },
      {
        icon: Group,
        text: 'Perfis',
        to: '/setting/users/groups',
      },
      {
        icon: OtherHouses,
        text: 'Bases',
        to: '/setting/bases',
      },
      {
        icon: LocalAirport,
        text: 'Aeronaves',
        to: '/setting/airplanes',
      },
      {
        icon: EventNote,
        text: 'Eventos Observados',
        to: '/setting/observed-events',
      },
    ],
  },
  {
    text: 'Power BI',
    icon: InsertChart,
    to: '/analytics/power_bi',
  },
];

export default menu;
