import { Route, Routes } from 'react-router-dom';
import React from 'react';

import ResetPassword from './elements/reset-password';
import Login from './elements/login';
import ForgetPassword from './elements/forget-password';

const Authentication = () => (
  <Routes>
    <Route element={<Login />} path="login" />
    <Route element={<ForgetPassword />} path="forget-password" />
    <Route element={<ResetPassword />} path="reset-password/:token" />
  </Routes>
);

export default Authentication;
