import React, { useMemo } from 'react';

import { useSnackbar } from 'notistack';
import { Avatar, Grow, IconButton, Stack, Typography } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useAuth } from '@/contexts/auth';

const UserInfobar: React.FC = () => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const salutation = useMemo(() => {
    const ndate = new Date();
    const hr = ndate.getHours();

    if (hr < 12) {
      return 'Bom dia';
    }
    if (hr >= 12 && hr <= 17) {
      return 'Boa tarde';
    }
    if (hr >= 17 && hr <= 24) return 'Boa noite';

    return 'Bem vindo';
  }, []);

  const logout = () => {
    enqueueSnackbar(`Até mais tarde${` ${auth.user?.name}!` || '!'}`, {
      variant: 'info',
    });
    auth.logout();
  };

  return (
    <Grow in>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Typography variant="button">
          <Stack spacing={-1}>
            <Typography variant="caption">
              <sup>{salutation}</sup>
            </Typography>
            <strong>{auth.user?.name}</strong>
          </Stack>
        </Typography>
        <Avatar alt={auth.user?.name} title={auth.user?.name} />
        <IconButton onClick={logout}>
          <Logout fontSize="inherit" />
        </IconButton>
      </Stack>
    </Grow>
  );
};

export default UserInfobar;
