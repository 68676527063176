import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Grid, Grow, Paper, Typography } from '@mui/material';
import Loading from '@/components/loading';

import { IdentifiedWeaknessCategory } from '.';

interface ListIdentifiedWeaknessesStatsProps {
  categories: IdentifiedWeaknessCategory[] | null;
  loading: boolean;
}

const colorPalette = [
  '#76A5E3', // Azul Claro
  '#F4A688', // Coral
  '#8CC2BE', // Verde Água
  '#F2D880', // Amarelo Pastel
  '#D495D1', // Lavanda Acentuada
  '#E3B47A', // Pêssego
  '#89C79F', // Verde Claro
  '#F4A6AE', // Rosa Claro
  '#A5A9D6', // Azul Lavanda
  '#E69580', // Salmão Claro
  '#B5CC9E', // Verde Pastel
  '#E8A877', // Laranja Claro
  '#9AA8F2', // Azul Médio
  '#D3B796', // Bege Claro
  '#8BA4E3', // Azul Suave
  '#9FD3C2', // Verde Gelado
  '#E88988', // Rosa Acentuado
  '#C5B878', // Amarelo Escuro
];

const getTheNextColor = (index: number) => {
  if (index > colorPalette.length - 1) {
    return colorPalette[index - colorPalette.length - 1];
  }
  return colorPalette[index];
};

const formatPercentage = (value: string) => {
  const number = Number(value);
  if (Number.isNaN(number)) return '-';
  return `${number.toFixed(2)}%`;
};

function customTooltip(props: any) {
  if (props.active && props.payload && props.payload.length) {
    return (
      <Box>
        <Paper sx={{ p: 1 }}>
          <Typography variant="subtitle2">
            {props.payload[0].payload.category}
          </Typography>
          <Typography>
            Proporção: {formatPercentage(props.payload[0].value)}
          </Typography>
          <Typography>
            Total de eventos: {props.payload[0].payload?.totalEvents ?? '0'}
          </Typography>
        </Paper>
      </Box>
    );
  }

  return null;
}

export const ListIdentifiedWeaknessesStats: React.FC<
  ListIdentifiedWeaknessesStatsProps
> = ({ loading, categories: rawCategories = [] }) => {
  const categories =
    rawCategories?.sort((a, b) => {
      if (a.proportion < b.proportion) {
        return 1;
      }
      if (a.proportion > b.proportion) {
        return -1;
      }
      return 0;
    }) ?? [];
  const shouldRenderChart = categories && categories?.length > 0 && !loading;

  return (
    <Grow in unmountOnExit>
      <Grid
        container
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(480px, auto))',
        }}
        sx={{ mt: 2, gap: 2, width: '100%', ml: 0 }}
      >
        <Loading backgroundColor="transparent" loading={loading} />
        {shouldRenderChart && (
          <Paper sx={{ p: 2, width: '100%', gridColumn: '1/-1' }}>
            <Typography sx={{ mb: 2, fontWeight: 'bold' }} variant="h6">
              Categorias de Fraquezas Identificadas
            </Typography>
            <ResponsiveContainer
              maxHeight={Math.min(1200, categories.length * 60)}
              width="100%"
            >
              <BarChart
                data={categories}
                layout="vertical"
                margin={{
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  name="Proporção"
                  ticks={[0, 25, 50, 75, 100]}
                  type="number"
                />
                <YAxis
                  dataKey="category"
                  name="Categoria"
                  type="category"
                  width={200}
                />
                <Tooltip content={customTooltip} formatter={formatPercentage} />
                <Bar
                  dataKey="proportion"
                  fill="#82ca9d"
                  name="Proporção"
                  textAnchor="end"
                >
                  <LabelList formatter={formatPercentage} position="right" />
                  {categories.map((_, index) => (
                    <Cell
                      fill={getTheNextColor(index)}
                      key={`cell-${index}`}
                      stroke={getTheNextColor(index)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            {categories?.length === 0 && !loading && (
              <span>
                Nenhum resultado para mostrar com o filtro selecionado.
              </span>
            )}
          </Paper>
        )}
      </Grid>
    </Grow>
  );
};
