import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit,
  Error,
  FilterList,
  Home,
} from '@mui/icons-material';
import Http from '@/services/http';
import { IPagination } from '@/models/pagination-model';
import { ICancelReason } from '@/models/cancel-reason-model';
import { useDrawer } from '@/contexts/drawer';
import Popover from '@/components/popover';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';

import CancelReasonFilterForm from './components/cancel-reason-filter-form';
import UpdateCancelReasonForm from '../update-cancel-reason';
import CreateCancelReasonForm from '../cancel-reason-event';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
  }),
  '&:nth-of-type(odd), :hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CancelReasonList: React.FC = () => {
  const drawer = useDrawer();
  const [searchParams, setSearchParams] = useSearchParams();

  const [cancelReasons, setCancelReasons] = useState<
    IPagination<ICancelReason>
  >({
    docs: [],
    limit: 0,
    offset: 0,
    totalDocs: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
  });

  const [loadingCancelReasons, setLoadingCancelReasons] =
    useState<boolean>(false);

  const submitFilter = useCallback(
    async (values: Record<string, any>) => {
      Object.entries(values).forEach(([key, value]) => {
        let queryValue;
        if (value instanceof Date) {
          queryValue = value.toISOString();
        } else if (!isEmpty(value)) {
          queryValue = value;
          if (typeof value === 'object' && value._id) {
            queryValue = value._id;
          }
        }

        /* Check is value is a boolean */
        if (typeof value === 'boolean') {
          if (value) queryValue = 'true';
          else queryValue = undefined;
        }

        if (queryValue) {
          searchParams.set(key, queryValue);
        } else {
          searchParams.delete(key);
        }
      });

      if (!values.page) {
        searchParams.delete('page');
      }

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchCancelReasons = useCallback(async () => {
    setLoadingCancelReasons(true);

    const { status, data } = await Http.get('cancel-reasons', {
      params: {
        ...Object.fromEntries(searchParams),
        limit: 5,
      },
    });

    if (status === 200) setCancelReasons(data);

    setLoadingCancelReasons(false);
  }, [searchParams]);

  const createCancelReason = () => {
    drawer.open({
      element: <CreateCancelReasonForm />,
      onClose(createdCancelReason) {
        if (createdCancelReason) {
          fetchCancelReasons();
        }
      },
    });
  };

  const editCancelReason = (cancelReason: ICancelReason) => {
    drawer.open({
      element: <UpdateCancelReasonForm cancelReason={cancelReason} />,
      onClose(updatedCancelReason) {
        if (updatedCancelReason) fetchCancelReasons();
      },
    });
  };

  useEffect(() => {
    fetchCancelReasons();
  }, [fetchCancelReasons]);

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'Configurações',
          },
          {
            text: 'Motivos de Cancelamento',
          },
        ]}
        headerChildren={
          <Stack alignItems="center" direction="row" spacing={1}>
            <Popover
              target={
                <IconButton color="secondary">
                  <FilterList />
                </IconButton>
              }
            >
              <CancelReasonFilterForm onSubmit={submitFilter} />
            </Popover>
            <IconButton
              color="secondary"
              onClick={() => {
                createCancelReason();
              }}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        }
        title="Motivos de Cancelamento"
      />
      <Paper sx={{ width: '100%' }}>
        <Collapse in={loadingCancelReasons} unmountOnExit>
          <Box
            alignItems="center"
            bgcolor="grey.100"
            display="flex"
            justifyContent="center"
            p={4}
          >
            <CircularProgress />
          </Box>
        </Collapse>
        <Collapse in={!loadingCancelReasons && cancelReasons.docs.length === 0}>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography variant="caption">
              <Error sx={{ fontSize: '56px' }} />
            </Typography>
            <Typography color="GrayText" variant="h5">
              Nenhum movito de cancelamento encontrado
            </Typography>
          </Stack>
        </Collapse>
        <Collapse in={!loadingCancelReasons || cancelReasons.docs.length > 0}>
          <TableContainer sx={{ overflowY: 'auto' }}>
            <Table
              aria-label="cancel reasons list"
              sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}
            >
              <TableBody>
                {cancelReasons.docs?.map((cancelReason) => (
                  <StyledTableRow key={cancelReason._id}>
                    <TableCell sx={{ width: '1%' }}>
                      <Tooltip title="Editar">
                        <IconButton
                          onClick={() => editCancelReason(cancelReason)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '1%' }}>
                      <Stack alignItems="center">
                        {cancelReason.active ? (
                          <Chip color="success" label="Ativa" />
                        ) : (
                          <Chip color="default" label="Não ativa" />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Nome',
                            value: cancelReason.name || '-',
                          },
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      <InfoLabel
                        items={[
                          {
                            title: 'Descrição',
                            value: cancelReason.description || '-',
                          },
                        ]}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
        {cancelReasons.totalPages > 1 && (
          <Box sx={{ m: 2 }}>
            <Pagination
              count={cancelReasons.totalPages}
              onChange={(event, selectedPage) =>
                submitFilter({ page: selectedPage.toString() })
              }
              page={cancelReasons.offset}
            />
          </Box>
        )}
      </Paper>
    </Stack>
  );
};

export default CancelReasonList;
