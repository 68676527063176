import {
  DatePickerElement,
  SelectElement,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useEffect, useState } from 'react';

import { Box, FormLabel, Grid, Paper, Stack } from '@mui/material';
import Http from '@/services/http';
import { IBase } from '@/models/base-model';
import { IAircraft } from '@/models/aircraft-model';

const ServiceForm: React.FC = () => {
  const [bases, setBases] = useState<IBase[]>([]);
  const [aircrafts, setAircrafts] = useState<IAircraft[]>([]);

  useEffect(() => {
    Http.get('bases', {
      params: {
        pagination: false,
      },
    }).then(({ status, data }) => {
      if (status === 200) setBases(data.docs || []);
    });
  }, []);

  useEffect(() => {
    Http.get('aircrafts', {
      params: {
        pagination: false,
      },
    }).then(({ status, data }) => {
      if (status === 200) setAircrafts(data.docs || []);
    });
  }, []);

  return (
    <Stack direction="column" spacing={2}>
      <Box>
        <FormLabel sx={{ mb: 1, display: 'block' }}>
          <strong>Dados do serviço</strong>
        </FormLabel>
        <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.100' }}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <SwitchElement defaultChecked label="Ativo" name="active" />
            </Grid>
            <Grid item md={4}>
              <SwitchElement label="Programado" name="programming" />
            </Grid>
            <Grid item md={4}>
              <TextFieldElement disabled label="Código" name="code" />
            </Grid>
            <Grid item md={4}>
              <TextFieldElement label="Registro" name="register" required />
            </Grid>
            <Grid item md={4}>
              <SelectElement
                label="Aeronave"
                name="aircraft"
                options={aircrafts.map((aircraft) => ({
                  id: aircraft._id,
                  label: aircraft.prefix,
                }))}
                required
              />
            </Grid>
            <Grid item md={4}>
              <SelectElement
                label="Base"
                name="base"
                options={bases.map((base) => ({
                  id: base._id,
                  label: base.name,
                }))}
                required
              />
            </Grid>
            <Grid item md={4}>
              <TextFieldElement label="Inspeção" name="inspection" required />
            </Grid>
            <Grid item md={4}>
              <TextFieldElement
                label="Complexidade"
                name="complexity"
                required
              />
            </Grid>
            <Grid item md={4}>
              <DatePickerElement
                label="Previsão para execução"
                name="executePrediction"
                required
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Stack>
  );
};

export default ServiceForm;
