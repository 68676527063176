import { FormContainer, SelectElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
/* eslint-disable import/no-duplicates */
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { downloadUrl } from '@/helpers/download-url';
import { useItemDrawer } from '@/contexts/drawer';

const ExportUsers: React.FC = () => {
  const form = useForm({
    defaultValues: {
      active: 'both',
    },
  });
  const drawerItem = useItemDrawer();

  const { enqueueSnackbar } = useSnackbar();

  const exportUsersSubmit = useCallback(
    async (values: Record<string, any>) => {
      const shouldFilter = values.active !== 'both';
      const active = values.active === 'active';

      const params: Record<string, boolean | string | undefined> = {
        active: undefined,
      };

      if (shouldFilter) {
        params.active = active === true ? 'true' : 'false';
      }

      const { data, status } = await Http.get(
        '/fast-tracking-users/fast-tracking-user-concluded',
        {
          params,
          responseType: 'blob',
        }
      );

      const fileURL = URL.createObjectURL(data);
      let fileName = '';
      if (shouldFilter) {
        fileName = `Usuários ${active ? 'ativos' : 'inativos'} - ${format(
          new Date(),
          'dd MMMM yyy',
          {
            locale: ptBR,
          }
        )}.xlsx`;
      } else {
        fileName = `Usuários - ${format(new Date(), 'dd MMMM yyy', {
          locale: ptBR,
        })}.xlsx`;
      }

      if (status === 200) {
        enqueueSnackbar('Usuários exportados com sucesso!', {
          variant: 'success',
        });

        downloadUrl(fileURL, fileName);

        drawerItem.close();
      } else
        enqueueSnackbar('Ocorreu um erro ao criar o usuário!', {
          variant: 'error',
        });
    },
    [drawerItem, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography
          sx={{ textTransform: 'uppercase', fontSize: 20, fontWeight: 'bold' }}
          variant="h1"
        >
          Exportar usuários
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
            autoComplete: 'false',
          }}
          onSuccess={exportUsersSubmit}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                {/* Ativo */}
                <SelectElement
                  label="Ativo"
                  name="active"
                  options={[
                    {
                      id: 'both',
                      label: 'Ambos',
                    },
                    {
                      id: 'active',
                      label: 'Ativos',
                    },
                    {
                      id: 'inactive',
                      label: 'Inativos',
                    },
                  ]}
                  sx={{
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Exportar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default ExportUsers;
