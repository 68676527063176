import React from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { IRif } from '@/models/rif-model';
import { getLengthLabel } from '@/helpers/get-length-label';

import RifManagmnetRowSupport from '../../components/rif-managment-row-support';
import RifManagmnetRowMaintenance from '../../components/rif-managment-row-maintenance';

interface RifConcludedTableProps {
  rifList: IRif[];
}

const isMaintenance = (rif: IRif) => rif?.service?.type === 'Maintenance';

const RifConcludedTable: React.FC<RifConcludedTableProps> = ({ rifList }) => (
  <>
    <Box
      sx={{
        width: '100%',
        textAlign: 'right',
        padding: '5px 15px 5px',
      }}
    >
      {rifList.length > 0 && (
        <Typography sx={{ fontSize: 14 }} variant="caption">
          {getLengthLabel(rifList.length, {
            pluralEntityName: 'documentos encontrados',
            uniqueEntityName: 'documento encontrado',
            emptyLabel: 'Nenhum documento encontrado',
          })}
        </Typography>
      )}
    </Box>
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table
          stickyHeader
          sx={{ maxWidth: '100%' /* , whiteSpace: 'nowrap' */ }}
        >
          <TableBody>
            {rifList.map((rif, i) =>
              isMaintenance(rif) ? (
                <RifManagmnetRowMaintenance key={i} rif={rif} />
              ) : (
                <RifManagmnetRowSupport key={i} rif={rif} />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </>
);

export default RifConcludedTable;
