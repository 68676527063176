/**
 * Downloads a text string as a file in the browser.
 *
 * @param {string} text - The text content to be downloaded as a file
 * @param {string} suggestFileName - The suggested name for the downloaded file
 *
 * @description
 * This function creates a temporary blob URL and triggers a download through
 * a dynamically created anchor element. The blob URL is automatically cleaned up
 * after 5 seconds to prevent memory leaks.
 *
 * @example
 * ```typescript
 * downloadTextAsFile('Hello, World!', 'hello.txt');
 * ```
 *
 * @returns {void}
 *
 * @throws {Error} May throw an error if document or URL APIs are not available
 * in the current environment
 */
export function downloadTextAsFile(text: string, suggestFileName: string) {
  const blob = new Blob([text], { type: 'octect/stream' });
  const blobUrl = URL.createObjectURL(blob);
  const el = document.createElement('a');
  el.href = blobUrl;

  el.setAttribute('download', suggestFileName);

  // Append to html el element page
  document.body.appendChild(el);

  // Start download
  el.click();

  // Clean up and remove the el
  el.parentNode?.removeChild(el);
  setTimeout(() => {
    URL.revokeObjectURL(blobUrl);
  }, 5000);
}
