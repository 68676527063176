import {
  AutocompleteElement,
  DatePickerElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useMemo, useState } from 'react';

import { unionBy } from 'lodash';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { Box, Button, debounce, Grid, Stack, Typography } from '@mui/material';
import { statusRifs } from '@/structs/status-rifs';
import { complexities } from '@/structs/complexities';
import Http from '@/services/http';
import { getCleanValues } from '@/helpers/get-clean-values';

interface IRifManagementFilterForm {
  defaultBases: Option[];
  defaultAircrafts: Option[];
  defaultValues?: Record<string, any>;
  onSubmit(values: Record<string, any>): Promise<void>;
  loading?: boolean;
}

interface Option {
  id: string;
  label: string;
}

interface Options {
  bases: Option[];
  aircrafts: Option[];
}

const RifManagementFilterForm: React.FC<IRifManagementFilterForm> = ({
  onSubmit,
  defaultValues,
  defaultBases,
  defaultAircrafts,
  loading = false,
}) => {
  const form = useForm({
    defaultValues,
  });

  const [options, setOptions] = useState<Options>({
    bases: defaultBases ?? [],
    aircrafts: defaultAircrafts ?? [],
  });

  const cleanForm = useMemo(() => getCleanValues(form.getValues()), [form]);

  const clearForm = useCallback(() => {
    form.reset(cleanForm);
    setTimeout(() => {
      form.handleSubmit(onSubmit)();
    }, 0);
  }, [form, cleanForm, onSubmit]);

  const fetchBase = useCallback(async (search?: string | null) => {
    const { status, data } = await Http.get('bases', {
      params: {
        ...(search ? { search } : {}),
      },
    });

    if (status === 200) {
      setOptions((old) => ({
        ...old,
        bases: unionBy(
          [
            ...old.bases,
            ...data.docs.map((b: any) => ({
              id: b._id,
              label: `${b.name} (${b.code})`,
            })),
          ],
          ({ id }) => id
        ),
      }));
    }
  }, []);

  const fetchAircrafts = useCallback(async (search?: string | null) => {
    const { status, data } = await Http.get('aircrafts', {
      params: {
        ...(search ? { search } : {}),
      },
    });

    if (status === 200) {
      setOptions((old) => ({
        ...old,
        aircrafts: unionBy(
          [
            ...old.aircrafts,
            ...data.docs.map((b: any) => ({
              id: b._id,
              label: `${b.prefix} (${b._model?.model ?? 'Sem Modelo'})`,
            })),
          ],
          ({ id }) => id
        ),
      }));
    }
  }, []);

  return (
    <Box sx={{ p: 2, width: '70vw' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography variant="caption">Filtros</Typography>
          <Stack direction="column" spacing={2}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <TextFieldElement label="Código RIF" name="code" />
              </Grid>
              <Grid item md={3}>
                <AutocompleteElement
                  autocompleteProps={{
                    ChipProps: { size: 'small' },
                    noOptionsText: loading ? 'Carregando...' : 'Nenhuma opção',
                    onOpen() {
                      if (options.bases.length === 0) {
                        fetchBase();
                      }
                    },
                    onInputChange: debounce((_, value, reason) => {
                      if (reason === 'input') {
                        fetchBase(value);
                      }
                    }, 300),
                  }}
                  label="Base"
                  matchId
                  name="base"
                  options={options.bases}
                />
              </Grid>
              <Grid item md={4}>
                <AutocompleteElement
                  autocompleteProps={{
                    ChipProps: { size: 'small' },
                    noOptionsText: loading ? 'Carregando...' : 'Nenhuma opção',
                    onOpen: () => {
                      if (options.aircrafts.length === 0) {
                        fetchAircrafts();
                      }
                    },
                    onInputChange: debounce((_, value, reason) => {
                      if (reason === 'input') {
                        fetchAircrafts(value);
                      }
                    }, 300),
                  }}
                  label="Aeronave"
                  matchId
                  name="aircraft"
                  options={options.aircrafts.map((a) => ({ ...a, key: a.id }))}
                />
              </Grid>
              <Grid item md={6}>
                <DatePickerElement
                  label="Previsão de Execução (de)"
                  name="executePredictionStartDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue(
                        'executePredictionStartDate',
                        startOfDay(value)
                      );
                    }
                  }}
                  onOpen={() => {
                    const shouldCleanDate = !isValid(
                      form.getValues().executePredictionStartDate
                    );
                    if (shouldCleanDate)
                      form.setValue('executePredictionStartDate', null);
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <DatePickerElement
                  label="Previsão de Execução (até)"
                  name="executePredictionEndDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue(
                        'executePredictionEndDate',
                        endOfDay(value)
                      );
                    }
                  }}
                  onOpen={() => {
                    const shouldCleanDate = !isValid(
                      form.getValues().executePredictionEndDate
                    );
                    if (shouldCleanDate)
                      form.setValue('executePredictionEndDate', null);
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <DatePickerElement
                  label="Início do RIF (De)"
                  name="startRifStartDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('startRifStartDate', startOfDay(value));
                    }
                  }}
                  onOpen={() => {
                    const shouldCleanDate = !isValid(
                      form.getValues().startRifStartDate
                    );
                    if (shouldCleanDate)
                      form.setValue('startRifStartDate', null);
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <DatePickerElement
                  label="Início do RIF (até)"
                  name="startRifEndDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('startRifEndDate', endOfDay(value));
                    }
                  }}
                  onOpen={() => {
                    const shouldCleanDate = !isValid(
                      form.getValues().startRifEndDate
                    );
                    if (shouldCleanDate) form.setValue('startRifEndDate', null);
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <AutocompleteElement
                  label="Complexidade"
                  matchId
                  name="complexity"
                  options={complexities}
                />
              </Grid>
              <Grid item md={4}>
                <AutocompleteElement
                  label="Status RIF"
                  matchId
                  name="status"
                  options={statusRifs}
                />
              </Grid>
              <Grid item md={4}>
                <AutocompleteElement
                  label="Tipo de serviço"
                  matchId
                  name="type"
                  options={[
                    {
                      id: 'Maintenance',
                      label: 'Manutenção',
                    },
                    {
                      id: 'Support',
                      label: 'Apoio',
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
              <Button onClick={clearForm}>Limpar</Button>
              <Button color="secondary" type="submit" variant="contained">
                Aplicar
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default RifManagementFilterForm;
