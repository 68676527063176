import styled from 'styled-components';

export const Select = styled.select`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 42px;
  border-radius: 4px;
  padding: 4px 8px;
`;

export const Input = styled.input`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 42px;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: transparent;
`;
