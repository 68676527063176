import { SwitchElement, TextFieldElement } from 'react-hook-form-mui';

import { Grid } from '@mui/material';

const BaseForm: React.FC = () => (
  <Grid container spacing={2}>
    <Grid item sm={12}>
      <TextFieldElement label="Código" name="code" required />
    </Grid>
    <Grid item sm={12}>
      <TextFieldElement label="Nome" name="name" required />
    </Grid>
    <Grid item xs={12}>
      <SwitchElement label="Ativa" name="active" />
    </Grid>
  </Grid>
);

export default BaseForm;
