import { useSearchParams } from 'react-router-dom';

import { Chip, Grow, Stack } from '@mui/material';

interface ITagDef {
  paramKey: string;
  valueGetter: (value: string) => string;
}

interface IFilterTags {
  tagDefs: ITagDef[];
  defaultSearchParams?: URLSearchParams;
}

const FilterTags: React.FC<IFilterTags> = ({
  tagDefs,
  defaultSearchParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
  return (
    <Stack direction="row" flexWrap="wrap" gap={1} justifyContent="flex-end">
      {tagDefs.map((tagDef) => (
        <Grow
          in={searchParams.has(tagDef.paramKey)}
          key={tagDef.paramKey}
          unmountOnExit
        >
          <Chip
            label={tagDef.valueGetter(
              searchParams.get(tagDef.paramKey) || tagDef.paramKey
            )}
            onDelete={() => {
              searchParams.delete(tagDef.paramKey);
              setSearchParams(searchParams);
            }}
          />
        </Grow>
      ))}
    </Stack>
  );
};

export default FilterTags;
