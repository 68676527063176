export const complexities = [
  {
    id: 'Line',
    label: 'Line',
  },
  {
    id: 'Base',
    label: 'Base',
  },
];

export const getComplexities = (code?: string | null) =>
  complexities.find((s) => s.id.toUpperCase() === code?.toUpperCase());
