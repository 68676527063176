import {
  AutocompleteElement,
  DatePickerElement,
  FormContainer,
} from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';

import fileDownload from 'js-file-download';
import { subMonths } from 'date-fns';
import { Box, Button, Grid, Paper } from '@mui/material';
import { Download, Home, Search } from '@mui/icons-material';
import Http from '@/services/http';
import { IRif } from '@/models/rif-model';
import Loading from '@/components/loading';
import Header from '@/components/layouts/header';

import RifConcludedTable from './table';

type FilterAutocompleteItem = {
  id: string;
  label: string;
};

type FilterFormValues = {
  startDate?: Date | null;
  endDate?: Date | null;
  base?: FilterAutocompleteItem | null;
  auditor?: FilterAutocompleteItem | null;
  mechanic?: FilterAutocompleteItem | null;
  maintenanceProcess?: string[] | null;
  maintenanceSupportProcess?: string[] | null;
};

const maintenanceProcesses = [
  'MANUTENÇÃO DE AERONAVES & COMPONENTES',
  'INSPEÇÃO OBRIGATÓRIA',
  'APROVAÇÃO PARA O RETORNO AO SERVIÇO',
];
const maintenanceSupportProcesses = [
  'CONTROLE TÉCNICO DE MANUTENÇÃO',
  'BIBLIOTECA TÉCNICA',
  'ENGENHARIA',
  'SUPRIMENTOS',
  'PLANEJAMENTO',
  'CONTROLE DE COMPETÊNCIAS',
];

const RifConcluded: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [bases, setBases] = useState([]);
  const [auditors, setAuditors] = useState([]);
  const [mechanics, setMechanics] = useState([]);
  const filterContext = useForm<FilterFormValues>({
    defaultValues: {
      endDate: new Date(),
      startDate: subMonths(new Date(), 1),
    },
  });
  const [rifList, setRifList] = useState<IRif[]>([]);

  const fetchBases = useCallback(async () => {
    const { data } = await Http.get(`bases?pagination=false`);
    setBases(data?.docs || []);
  }, []);

  const fetchAuditors = useCallback(async () => {
    const { data } = await Http.get(`fast-tracking-users?pagination=false`);
    setAuditors(data?.docs || []);
  }, []);

  const fetchMechanics = useCallback(async () => {
    const roles = ['MECÂNICO', 'APOIO', 'AUDITOR', 'AVALIADOR'];
    const { data } = await Http.get(
      `fast-tracking-users?role=${roles.join('&&role=')}&pagination=false`
    );
    setMechanics(data?.docs || []);
  }, []);

  const downloadExcel = useCallback(
    async (values?: FilterFormValues) => {
      const filterValues = values || filterContext.getValues();
      const { data } = await Http.get(`reports/rifs-concluded`, {
        responseType: 'blob',
        params: {
          ...filterValues,
          base: filterValues.base?.id,
          auditor: filterValues.auditor?.id,
          mechanic: filterValues.mechanic?.id,
        },
      });

      fileDownload(data, `RIFS_REALIZADOS_${Date.now()}.xlsx`);
    },
    [filterContext]
  );

  const search = useCallback(
    async (values?: FilterFormValues) => {
      const filterValues = values || filterContext.getValues();
      setLoading(true);
      const { data } = await Http.get(`rifs/list-concluded`, {
        params: {
          ...filterValues,
          base: filterValues.base?.id,
          auditor: filterValues.auditor?.id,
          mechanic: filterValues.mechanic?.id,
        },
      });
      setLoading(false);
      setRifList(data);
    },
    [filterContext]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchBases(),
      fetchAuditors(),
      fetchMechanics(),
      search(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [fetchBases, fetchAuditors, fetchMechanics, search]);

  return (
    <>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'RIF',
          },
          {
            text: 'RIFs Realizados',
          },
        ]}
        title="RIFs Realizados"
      />
      <Paper sx={{ p: 2, mt: 2 }}>
        <FormContainer formContext={filterContext} onSuccess={() => search()}>
          <Grid container>
            <Grid item md={6} sm={12}>
              <DatePickerElement label="Data Inicio" name="startDate" />
            </Grid>
            <Grid item md={6} sm={12}>
              <DatePickerElement label="Data Final" name="endDate" />
            </Grid>
            <Grid item md={6} sm={12}>
              <AutocompleteElement
                label="Processo de manutenção"
                multiple
                name="maintenanceProcess"
                options={maintenanceProcesses}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <AutocompleteElement
                label="Processo de apoio à manutenção"
                multiple
                name="maintenanceSupportProcess"
                options={maintenanceSupportProcesses}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <AutocompleteElement
                label="Base"
                name="base"
                options={bases?.map((base: any) => ({
                  id: base?._id,
                  label: base?.name,
                }))}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <AutocompleteElement
                label="Auditor"
                name="auditor"
                options={auditors?.map((auditor: any) => ({
                  id: auditor?._id,
                  label: `${auditor?.registration} - ${auditor?.name}`,
                }))}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <AutocompleteElement
                label="Auditado"
                name="mechanic"
                options={mechanics?.map((mechanic: any) => ({
                  id: mechanic?._id,
                  label: `${mechanic?.registration} - ${mechanic?.name}`,
                }))}
              />
            </Grid>
          </Grid>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}
          >
            <Button
              color="secondary"
              onClick={() => downloadExcel()}
              startIcon={<Download />}
              variant="outlined"
            >
              Baixar Excel
            </Button>
            <Button
              color="secondary"
              startIcon={<Search />}
              type="submit"
              variant="contained"
            >
              Procurar
            </Button>
          </Box>
        </FormContainer>
      </Paper>
      <Grid
        container
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(480px, auto))',
        }}
        sx={{ mt: 2, gap: 2, width: '100%', ml: 0 }}
      >
        <Loading backgroundColor="transparent" loading={loading} />
      </Grid>
      <RifConcludedTable rifList={rifList} />
    </>
  );
};

export default RifConcluded;
