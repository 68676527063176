import { useCookies } from 'react-cookie';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import jwtDecode from 'jwt-decode';
import { AxiosResponse } from 'axios';

import { useDrawer } from './drawer';
import http from '../services/http';

interface AuthLoginResponse {
  authenticated: boolean;
  code: 'USER_OK' | 'USER_BLOCKED' | 'USER_PASS_INVALID';
}
interface AuthProviderProps {
  loginUrl: string;
  baseUrl: string;
}

interface AuthUserInfo {
  id: string;
  name: string;
  status: 'pending' | 'active';
  role: string;
}

type ChangeUserInfoParams = { token: string; user: AuthUserInfo };

interface AuthContextProperties {
  authenticated: boolean;
  user: AuthUserInfo | null;
  config: AuthProviderProps;
  login(
    username: string,
    password: string,
    sso?: boolean
  ): Promise<AuthLoginResponse>;
  changeUserInfo: (params: ChangeUserInfoParams) => void;
  logout(): Promise<any>;
  getInitialRoute(): string;
}

const STORAGE_USER_INFO = 'user_info';

const AuthContext = createContext<AuthContextProperties>(
  {} as AuthContextProperties
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('auth context required');
  return context;
};

const AuthProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<AuthProviderProps>>
> = ({ children, loginUrl = '/auth/login', baseUrl = '/' }) => {
  const drawer = useDrawer();
  const [cookies, setCookie, removeCookie] = useCookies(['user_session']);

  http.defaults.headers.common.Authorization = `Bearer ${cookies.user_session}`;

  const [authenticated, setAuthenticated] = useState<boolean>(
    () => !!cookies.user_session
  );
  const [user, setUser] = useState<AuthUserInfo | null>(() => {
    try {
      const userInfo = localStorage.getItem(STORAGE_USER_INFO);
      if (!userInfo || !cookies.user_session) return null;
      return JSON.parse(userInfo);
    } catch {
      return null;
    }
  });
  const setUserInfo = useCallback(
    (userInfoSession: any) => {
      const { token, user: userInfo } = userInfoSession;
      const { exp }: any = jwtDecode(token) ?? {};

      setCookie('user_session', token, {
        expires: new Date(exp * 1000),
        secure: true,
        path: '/',
      });
      localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userInfo));
      setUser(userInfo);
      setAuthenticated(true);
    },
    [setCookie]
  );

  const loginHandler = useCallback(
    async (login: string, password: string): Promise<AuthLoginResponse> => {
      const { status, data } = await http.post('auth/login', {
        login,
        password,
      });
      if (status === 200) {
        setUserInfo(data);
        return {
          authenticated: true,
          code: 'USER_OK',
        };
      }
      setAuthenticated(false);
      return {
        authenticated: false,
        code: status === 403 ? 'USER_BLOCKED' : 'USER_PASS_INVALID',
      };
    },
    [setUserInfo]
  );
  const logoutHandler = useCallback(async () => {
    setUser(null);
    setAuthenticated(false);
    removeCookie('user_session', {
      path: '/',
    });
    localStorage.removeItem(STORAGE_USER_INFO);

    drawer.closeAll();
  }, [removeCookie, setAuthenticated, drawer]);

  const getInitialRouteHandler = useCallback(() => baseUrl, [baseUrl]);

  const props = useMemo(
    () => ({
      authenticated,
      user,
      login: loginHandler,
      logout: logoutHandler,
      changeUserInfo: setUserInfo,
      getInitialRoute: getInitialRouteHandler,
      config: { loginUrl, baseUrl },
    }),
    [
      authenticated,
      baseUrl,
      getInitialRouteHandler,
      loginHandler,
      loginUrl,
      logoutHandler,
      setUserInfo,
      user,
    ]
  );

  useEffect(() => {
    http.interceptors.response.use((response: AxiosResponse) => {
      if (response.status === 401) {
        logoutHandler();
      }
      return response;
    });
  }, [logoutHandler]);

  return <AuthContext.Provider value={props}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
