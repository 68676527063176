import { pt } from 'yup-locale-pt';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type IValidator = typeof Yup & { adapter: typeof yupResolver };

const Validator: IValidator = { ...Yup, adapter: yupResolver };

Validator.setLocale(pt);

export default Validator;
