import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import { useItemDrawer } from '@/contexts/drawer';

import CancelReasonForm from '../components/cancel-reason-form';

const CreateCancelReasonForm: React.FC = () => {
  const form = useForm({
    defaultValues: { active: true },
  });
  const drawerItem = useItemDrawer();

  const { enqueueSnackbar } = useSnackbar();

  const createCancelReasonSubmit = useCallback(
    async (values: any) => {
      const { status, data } = await Http.post('cancel-reasons', values);

      if (status === 201) {
        enqueueSnackbar('Movivo de cancelamento criado com sucesso!', {
          variant: 'success',
        });
        drawerItem.close(data);
      } else
        enqueueSnackbar('Ocorreu um erro ao criar o motivo de cancelamento!', {
          variant: 'error',
        });
    },
    [drawerItem, enqueueSnackbar]
  );

  return (
    <Box sx={{ p: 4, flexGrow: 1 }}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          CRIAR MOTIVO DE CANCELAMENTO
        </Typography>
        <FormContainer
          formContext={form}
          FormProps={{
            style: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
          }}
          onSuccess={createCancelReasonSubmit}
        >
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <CancelReasonForm />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button type="submit" variant="contained">
              Criar
            </Button>
            <Button onClick={() => drawerItem.close()}>Cancelar</Button>
          </Stack>
        </FormContainer>
      </Stack>
    </Box>
  );
};

export default CreateCancelReasonForm;
