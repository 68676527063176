import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
} from '@mui/material';
import {
  ArrowForward,
  Check,
  Close,
  Home,
  Refresh as LoadingIcon,
} from '@mui/icons-material';
import { getServiceStatus } from '@/structs/status-services';
import { getStatusFile } from '@/structs/status-files';
import Http from '@/services/http';
import { IService, ServiceStatusEnum } from '@/models/service-model';
import { IFile } from '@/models/file-model';
import { IBase } from '@/models/base-model';
import { IAircraft } from '@/models/aircraft-model';
import { useDialog } from '@/contexts/dialog';
import Loading from '@/components/loading';
import Header from '@/components/layouts/header';
import InfoLabel from '@/components/Info/label';

import { Input, Select } from './styles';

const FilesDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<IFile | undefined>();
  const confirmationDialog = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const [aircrafts, setAircrafts] = useState([]);
  const [bases, setBases] = useState<IBase[]>([]);
  const [services, setServices] = useState<Array<IService>>([]);

  const sortServices = (s: Array<IService>) => {
    if (s.length > 0) {
      return [
        ...s.filter(
          (service) =>
            !service.base || !service.aircraft || !service.executePrediction
        ),
        ...s.filter((service) => service.base && service.aircraft),
      ];
    }
    return [];
  };

  const fetchFile = useCallback(async () => {
    const { status, data } = await Http.get(`files/${id}`);

    if (status === 200) {
      setFile(data);
      setServices(sortServices(data?.services || []));
    } else {
      navigate('/service/upload');
    }
  }, [navigate, id]);

  const fetchBases = useCallback(async () => {
    const { data } = await Http.get(`bases?pagination=false`);
    setBases(data?.docs || []);
  }, []);

  const fetchAircrafts = useCallback(async () => {
    const { data } = await Http.get(`aircrafts?pagination=false`);
    setAircrafts(data?.docs || []);
  }, []);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchFile(), fetchAircrafts(), fetchBases()]).finally(() => {
      setLoading(false);
    });
  }, [fetchFile, fetchAircrafts, fetchBases]);

  const handleCancel = async () => {
    const { status, data } = await Http.patch(`files/${id}/cancel`);

    if (status === 200) {
      window.location.reload();
      enqueueSnackbar('Arquivo cancelado com sucesso!', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(
        data?.message || 'Ocorreu um erro ao cancelar o arquivo.',
        {
          variant: 'error',
        }
      );
    }
  };

  const handleCheck = async () => {
    const { status, data } = await Http.patch(`files/${id}/check`);

    if (status === 200) {
      window.location.reload();
      enqueueSnackbar('Arquivo conferido com sucesso!', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(
        data?.message || 'Ocorreu um erro ao conferir o arquivo.',
        {
          variant: 'error',
        }
      );
    }
  };

  const handleClickCancel = () => {
    confirmationDialog?.open({
      title: 'Cancelar importação',
      element:
        'Você tem certeza que deseja continuar? Todos os serviços dessa importação serão afetados com essa ação',
      buttons: [
        {
          label: 'Voltar',
          callback: async () =>
            confirmationDialog?.current?.onClose &&
            confirmationDialog?.current?.onClose(),
          options: {
            variant: 'text',
            color: 'primary',
          },
        },
        {
          label: 'Cancelar',
          callback: handleCancel,
          options: {
            color: 'error',
          },
        },
      ],
    });
  };

  const handleClickCheck = () => {
    confirmationDialog?.open({
      title: 'Conferir importação',
      element:
        'Você tem certeza que deseja continuar? Todos os serviços dessa importação vão passar a ser visíveis no sistema',
      buttons: [
        {
          label: 'Voltar',
          callback: async () =>
            confirmationDialog?.current?.onClose &&
            confirmationDialog?.current?.onClose(),
          options: {
            variant: 'text',
            color: 'primary',
          },
        },
        {
          label: 'Conferir',
          callback: handleCheck,
          options: {
            color: 'success',
          },
        },
      ],
    });
  };

  const getAircraftLabel = (aircraft?: {
    prefix: any;
    _model: { manufacturer: any; model: any };
  }) => {
    if (!aircraft) return '';
    return `${aircraft.prefix} (${
      aircraft?._model?.manufacturer && aircraft?._model?.model
        ? `${aircraft?._model?.manufacturer} - ${aircraft?._model?.model})`
        : null
    }`;
  };

  const handleChangeBase = async (newBase: any, service: IService) => {
    const { status } = await Http.put(`services/${service._id}`, {
      base: newBase,
      errorReason: '',
      status: ServiceStatusEnum.imported,
    });
    if (status === 200) {
      enqueueSnackbar('Serviço atualizado com sucesso!', {
        variant: 'success',
      });
    }
  };

  const handleChangeAircraft = async (newAircraft: any, service: IService) => {
    const { status } = await Http.put(`services/${service._id}`, {
      aircraft: newAircraft,
      errorReason: '',
      status: ServiceStatusEnum.imported,
    });
    if (status === 200) {
      enqueueSnackbar('Serviço atualizado com sucesso!', {
        variant: 'success',
      });
    }
  };

  const handleChangeExecuturePrediction = async (
    newExecuturePrediction: any,
    service: IService
  ) => {
    const { status } = await Http.put(`services/${service._id}`, {
      executePrediction: newExecuturePrediction,
      errorReason: '',
      status: ServiceStatusEnum.imported,
    });
    if (status === 200) {
      enqueueSnackbar('Serviço atualizado com sucesso!', {
        variant: 'success',
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Header
        breadcrumbs={[
          {
            icon: Home,
          },
          {
            text: 'Serviços',
            link: '/service/maintenance',
          },
          {
            text: 'Importação',
            link: '/service/upload',
          },
        ]}
        title="Gestão arquivo"
      />
      <Paper sx={{ overflow: 'hidden' }}>
        <Loading loading={loading} />
        <Collapse in={!loading} unmountOnExit>
          <Box sx={{ m: 3 }}>
            <Stack direction="column" spacing={3}>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>Arquivo</strong>
                </FormLabel>
                <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                  <Grid container>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Nome',
                            value: file?.archiveName,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Autor do envio',
                            value: file?.uploadedBy?.name,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Data do envio',
                            value: file?.dateUploaded
                              ? format(
                                  new Date(file?.dateUploaded),
                                  'dd/MM/yyyy HH:mm'
                                )
                              : null,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md>
                      <InfoLabel
                        items={[
                          {
                            title: 'Status',
                            value: (
                              <Chip
                                label={getStatusFile(file?.status || '')?.label}
                                size="small"
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                                sx={{
                                  bgcolor: getStatusFile(file?.status || '')
                                    ?.color,
                                  color: 'white',
                                  width: 'fit-content',
                                  px: 2,
                                  py: 1,
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box>
                <FormLabel sx={{ mb: 1, display: 'block' }}>
                  <strong>
                    Serviços importados no documento ({file?.servicesCount})
                  </strong>
                </FormLabel>
                {services?.length ? (
                  <Stack direction="column" spacing={1}>
                    {services?.map((service) => (
                      <Stack
                        component={Paper}
                        divider={<Divider />}
                        elevation={0}
                        key={service._id}
                        sx={{
                          bgcolor: 'grey.100',
                        }}
                      >
                        <Grid alignItems="center" container sx={{ p: 1 }}>
                          <Grid item width={60}>
                            <IconButton
                              component={Link}
                              to={`/service/${service._id}`}
                            >
                              <ArrowForward />
                            </IconButton>
                          </Grid>
                          <Grid item md={3}>
                            {!service?.aircraft && (
                              <Select
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>
                                ) =>
                                  handleChangeAircraft(
                                    event.target.value,
                                    service
                                  )
                                }
                                value={service?.aircraft?._id}
                              >
                                <option value="">Selecione a aeronave</option>
                                {aircrafts?.map((aircraft: IAircraft) => (
                                  <option value={aircraft?._id as string}>
                                    {aircraft?.prefix}
                                  </option>
                                ))}
                              </Select>
                            )}
                            {service?.aircraft && (
                              <InfoLabel
                                items={[
                                  {
                                    title: 'Aeronave',
                                    value: getAircraftLabel(service?.aircraft),
                                  },
                                ]}
                              />
                            )}
                          </Grid>
                          <Grid item md={2}>
                            <InfoLabel
                              items={[
                                {
                                  title: 'Inspeção',
                                  value: service?.inspection,
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item md>
                            {!service?.base && (
                              <Select
                                onChange={(
                                  e: React.ChangeEvent<HTMLSelectElement>
                                ) => handleChangeBase(e.target.value, service)}
                                value={service?.base?._id as string}
                              >
                                <option value="">Selecione a base</option>
                                {bases?.map((base) => (
                                  <option value={base?._id}>
                                    {base?.name}
                                  </option>
                                ))}
                              </Select>
                            )}
                            {service?.base && (
                              <InfoLabel
                                items={[
                                  {
                                    title: 'Base',
                                    value: service?.base?.name,
                                  },
                                ]}
                              />
                            )}
                          </Grid>
                          <Grid item md>
                            {!service?.executePrediction && (
                              <Input
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleChangeExecuturePrediction(
                                    e.target.value,
                                    service
                                  )
                                }
                                type="datetime-local"
                                value={service?.executePrediction}
                              />
                            )}
                            {service?.executePrediction && (
                              <InfoLabel
                                items={[
                                  {
                                    title: 'Previsão de execução',
                                    value: service.executePrediction
                                      ? format(
                                          new Date(service.executePrediction),
                                          'dd/MM/yyyy HH:mm'
                                        )
                                      : 'Data não informada',
                                  },
                                ]}
                              />
                            )}
                          </Grid>
                          <Grid item md>
                            <InfoLabel
                              items={[
                                {
                                  title: 'Status',
                                  value: (
                                    <Chip
                                      label={`${
                                        getServiceStatus(service?.status)?.label
                                      } ${
                                        service?.status ===
                                        ServiceStatusEnum.error
                                          ? 'ⓘ'
                                          : ''
                                      }`}
                                      size="small"
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                      }}
                                      sx={{
                                        bgcolor: getServiceStatus(
                                          service?.status
                                        )?.color,
                                        color: 'white',
                                        width: 'fit-content',
                                        px: 2,
                                        py: 1,
                                      }}
                                      title={service?.errorReason}
                                    />
                                  ),
                                },
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    ))}
                  </Stack>
                ) : (
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      color: 'grey.700',
                      my: 2,
                    }}
                  >
                    <span
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Importação em processamento. Aguarde uns instantes...
                      <LoadingIcon />
                    </span>
                  </Stack>
                )}
              </Box>
              <Stack
                direction="row"
                gap={1}
                justifyContent="flex-end"
                spacing={1}
              >
                <Button
                  color="error"
                  disabled={file?.status === ServiceStatusEnum.canceled}
                  onClick={handleClickCancel}
                  startIcon={<Close />}
                  title={`${
                    file?.status === ServiceStatusEnum.canceled
                      ? 'O arquivo já está cancelado'
                      : 'Cancelar arquivo'
                  }`}
                  variant="contained"
                >
                  {file?.status === ServiceStatusEnum.canceled
                    ? 'O arquivo já está cancelado'
                    : 'Cancelar arquivo'}
                </Button>

                <Button
                  color="success"
                  disabled={file?.status === ServiceStatusEnum.checked}
                  onClick={handleClickCheck}
                  startIcon={<Check />}
                  title={`${
                    file?.status === ServiceStatusEnum.checked
                      ? 'Arquivo conferido'
                      : 'Conferir arquivo'
                  }`}
                  variant="contained"
                >
                  {file?.status === ServiceStatusEnum.checked
                    ? 'Arquivo conferido'
                    : 'Conferir arquivo'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Collapse>
      </Paper>
    </Stack>
  );
};

export default FilesDetails;
