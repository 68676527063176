import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';

import { Box, Grow, IconButton, Paper, Stack } from '@mui/material';
import { Menu, MenuOpen } from '@mui/icons-material';
import menu from '@/structs/navigation';
import { useAuth } from '@/contexts/auth';

import UserInfobar from '../auth/user-infobar';
import Navigation, { NavigationProvider } from '../auth/navigation';

export const AuthLayout: React.FC = () => {
  const auth = useAuth();

  if (!auth.authenticated) return <Navigate to={auth.config.loginUrl} />;

  return (
    <NavigationProvider>
      {({ open, toggleMenu }) => (
        <Stack
          direction="row"
          spacing={0}
          sx={{
            bgcolor: (theme) => theme.palette.grey[200],
            minHeight: '100vh',
          }}
        >
          <Box>
            <Navigation menu={menu} />
          </Box>
          <Box
            sx={{
              position: 'relative',
              minHeight: '100%',
              width: 'auto',
              overflow: 'auto',
              flex: 1,
            }}
          >
            <Paper
              elevation={0}
              square
              sx={{
                display: 'flex',
                px: 3,
                py: 2,
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: (theme) => theme.palette.grey[100],
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grow in={open} unmountOnExit>
                  <IconButton onClick={() => toggleMenu()}>
                    <MenuOpen />
                  </IconButton>
                </Grow>
                <Grow in={!open} unmountOnExit>
                  <IconButton onClick={() => toggleMenu()}>
                    <Menu />
                  </IconButton>
                </Grow>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <UserInfobar />
              </Box>
            </Paper>
            <Box sx={{ mx: 3, my: 2 }}>
              <Outlet />
            </Box>
          </Box>
        </Stack>
      )}
    </NavigationProvider>
  );
};
