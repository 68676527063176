import {
  AutocompleteElement,
  DatePickerElement,
  FormContainer,
  SelectElement,
} from 'react-hook-form-mui';
import React, { useCallback, useEffect, useState } from 'react';

import { subYears } from 'date-fns';
import { Box, Button, Grid, Paper } from '@mui/material';
import { Download } from '@mui/icons-material';
import Http from '@/services/http';

import { ListIdentifiedWeaknessesStats } from './list-identified-weaknesses-stats';
import { ListIdentifiedWeaknessesByCategory } from './list-identified-weaknesses-by-category';
import GeneralInfoSection from './general-info';

export type IdentifiedWeaknessCategory = {
  category: string;
  proportion: number;
};

export type DashboardFilter = {
  base: {
    id: string;
  } | null;
  aircraftModel: {
    id: string;
  } | null;
  type: string;
  startDate: Date;
  endDate: Date;
};

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [identifiedWeaknessCategories, setIdentifiedWeaknessCategories] =
    React.useState<IdentifiedWeaknessCategory[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<IdentifiedWeaknessCategory | null>(null);
  // Filters
  const [bases, setBases] = useState([]);
  const [aircraftModels, setAircraftModels] = useState([]);
  const [filter, setFilter] = useState<DashboardFilter | null>(null);

  const defaultStartDate = React.useMemo(() => subYears(new Date(), 1), []);
  const defaultEndDate = React.useMemo(() => new Date(), []);

  const fetchBases = useCallback(async () => {
    const { data } = await Http.get(`bases?pagination=false`);
    setBases(data?.docs || []);
  }, []);

  const fetchAircraftModels = useCallback(async () => {
    const { data } = await Http.get(`aircraft-models?pagination=false`);
    setAircraftModels(data?.docs || []);
  }, []);

  const fetchIdentifiedWeaknessCategories = React.useCallback(async () => {
    setLoading(true);
    const parsedFilter = {
      base: filter?.base?.id || '',
      aircraftModel: filter?.aircraftModel?.id || '',
      startDate:
        filter?.startDate?.toISOString() || defaultStartDate.toISOString(),
      endDate: filter?.endDate?.toISOString() || defaultEndDate.toISOString(),
      type: filter?.type || '',
    };
    const queryParams = parsedFilter
      ? new URLSearchParams(parsedFilter).toString()
      : '';
    const { data } = await Http.get(
      `dashboard/identified-weaknesses?${queryParams}`
    );
    setIdentifiedWeaknessCategories(data);
    setLoading(false);
  }, [defaultEndDate, defaultStartDate, filter]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchBases(),
      fetchAircraftModels(),
      fetchIdentifiedWeaknessCategories(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [fetchBases, fetchAircraftModels, fetchIdentifiedWeaknessCategories]);

  const handleToggleCategory = (category: IdentifiedWeaknessCategory) => {
    if (category === selectedCategory) {
      setSelectedCategory(null);
      return;
    }
    setSelectedCategory(category);
  };

  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <FormContainer
          defaultValues={{
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            aircraftModel: null,
            base: null,
            type: 'all',
          }}
          onSuccess={(data) => {
            setFilter(data);
          }}
        >
          <Grid container>
            <Grid item md={6} sm={12}>
              <DatePickerElement label="Data Inicio" name="startDate" />
            </Grid>
            <Grid item md={6} sm={12}>
              <DatePickerElement label="Data Final" name="endDate" />
            </Grid>
            <Grid item md={4} sm={12}>
              <AutocompleteElement
                label="Base"
                name="base"
                options={bases?.map((base: any) => ({
                  id: base?._id,
                  label: base?.name,
                }))}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <SelectElement
                label="Tipo de processo"
                name="type"
                options={[
                  {
                    id: 'all',
                    label: 'Todos',
                  },
                  {
                    id: 'Maintenance',
                    label: 'Manutenção',
                  },
                  {
                    id: 'Support',
                    label: 'Apoio',
                  },
                ]}
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <AutocompleteElement
                label="Modelo de Aeronave"
                name="aircraftModel"
                options={aircraftModels?.map((aircraftModel: any) => ({
                  id: aircraftModel?._id,
                  label: `${aircraftModel?.manufacturer} ${aircraftModel.model}`,
                }))}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              color="secondary"
              startIcon={<Download />}
              type="submit"
              variant="contained"
            >
              Filtrar Dados
            </Button>
          </Box>
        </FormContainer>
      </Paper>
      <Paper
        sx={{
          p: 2,
          width: '100%',
          gridColumn: '1/-1',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          mt: 2,
        }}
      >
        {identifiedWeaknessCategories?.map((category) => (
          <Button
            key={category.category}
            onClick={() => handleToggleCategory(category)}
            variant={category === selectedCategory ? 'contained' : 'outlined'}
          >
            {category.category}
          </Button>
        ))}
      </Paper>
      {selectedCategory ? (
        <ListIdentifiedWeaknessesByCategory category={selectedCategory} />
      ) : (
        <ListIdentifiedWeaknessesStats
          categories={identifiedWeaknessCategories}
          loading={loading}
        />
      )}
      <GeneralInfoSection />
    </>
  );
};

export default Dashboard;
