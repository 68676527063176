import {
  SelectElement,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';

import { Grid } from '@mui/material';
import { rolesList } from '@/helpers/roles';

interface UserFormProps {
  showPasswordField?: boolean;
}

const UserForm: React.FC<UserFormProps> = ({ showPasswordField = false }) => (
  <Grid container spacing={2}>
    <Grid item md={2}>
      <SwitchElement label="ATIVO" name="active" required />
    </Grid>
    <Grid item md={2}>
      <TextFieldElement label="Registro" name="registration" required />
    </Grid>
    <Grid item md={4}>
      <TextFieldElement label="Nome" name="name" required />
    </Grid>
    <Grid item md={4}>
      <TextFieldElement label="Email" name="email" required type="email" />
    </Grid>
    <Grid item md={4}>
      <TextFieldElement label="Login" name="login" required />
    </Grid>
    {showPasswordField && (
      <Grid item md={4}>
        <TextFieldElement
          label="Senha"
          name="password"
          required
          type="password"
        />
      </Grid>
    )}
    <Grid item md={4}>
      <SelectElement
        label="Cargo"
        name="role"
        options={rolesList.map((role) => ({ id: role, label: role }))}
        required
      />
    </Grid>
  </Grid>
);

export default UserForm;
