import { AutocompleteElement, TextFieldElement } from 'react-hook-form-mui';
import { useMemo } from 'react';

import { Grid } from '@mui/material';
import { OBSERVED_EVENTS_CATEGORIES } from '@/models/observed-event-model';

const ObservedEventForm: React.FC = () => {
  const rawCategories = useMemo(() => OBSERVED_EVENTS_CATEGORIES, []);
  const parsedCategories = useMemo(
    () =>
      rawCategories.map((category, index) => ({
        id: category,
        label: `${String.fromCharCode(65 + index)} - ${category}`.toUpperCase(),
      })),
    [rawCategories]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextFieldElement label="Nome" name="name" required />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteElement
          autocompleteProps={{
            sx: {
              textTransform: 'uppercase',
            },
          }}
          label="Categoria"
          matchId
          name="category"
          options={parsedCategories}
        />
      </Grid>
    </Grid>
  );
};

export default ObservedEventForm;
