import React, { ReactNode, useMemo } from 'react';

import { Stack, StackProps, Typography } from '@mui/material';

interface LabelItemProps {
  title: string;
  value: ReactNode;
}

const LabelItem: React.FC<LabelItemProps> = ({ title, value }) => {
  const labelValue = useMemo(() => {
    if (typeof value === 'string' || typeof value === 'number') {
      return <Typography sx={{ fontSize: 14 }}>{value}</Typography>;
    }

    if (React.isValidElement(value)) {
      return value;
    }

    return (
      <Typography
        sx={(theme) => ({ color: theme.palette.grey[500], fontSize: 14 })}
      >
        N/A
      </Typography>
    );
  }, [value]);

  return (
    <Stack direction="column">
      <Typography sx={{ fontSize: 12 }} variant="caption">
        {title}
      </Typography>
      {labelValue}
    </Stack>
  );
};

const InfoLabel: React.FC<{
  items: LabelItemProps[];
  direction?: StackProps['direction'];
}> = ({ items, direction }) => (
  <Stack direction={direction ?? 'column'} spacing={1}>
    {items.map((item, i) => (
      <LabelItem {...item} key={i} />
    ))}
  </Stack>
);

export default InfoLabel;
