import { Box, Typography } from '@mui/material';
import theme from '@/styles';

export const GeneralItem = ({
  count,
  label,
}: {
  count?: string | number;
  label?: string;
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flexWrap: 'wrap',
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main,
      p: 2,
      borderRadius: 2,
    }}
  >
    <Typography
      sx={{
        color: theme.palette.secondary.contrastText,
        width: '100%',
        fontSize: '1.5rem',
        fontWeight: 'bold',
      }}
      variant="body1"
    >
      {count}
    </Typography>
    <Typography
      sx={{
        color: theme.palette.secondary.contrastText,
        fontSize: '1rem',
      }}
      variant="body2"
    >
      {label}
    </Typography>
  </Box>
);
