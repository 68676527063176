import React from 'react';

import { Grid, Paper, Stack, Typography } from '@mui/material';
import Http from '@/services/http';
import Loading from '@/components/loading';

import { GeneralItem } from './general-info-item';

type GeneralInfo = {
  auditedUsersCount: number;
  rifsDoneCount: number;
  processesCount: number;
  percentageOfWeaknessesIdentified: number;
  percentageOfUsersAudited: number;
  percentageOfProcessesAudited: number;
};

const GeneralInfoSection = () => {
  const [loading, setLoading] = React.useState(false);
  const [generalInfo, setGeneralInfo] = React.useState<GeneralInfo | null>(
    null
  );

  const fetchGeneralInfo = React.useCallback(async () => {
    setLoading(true);
    const { data } = await Http.get(`dashboard/general-info`);
    setGeneralInfo(data);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    fetchGeneralInfo();
  }, [fetchGeneralInfo]);

  return (
    <Paper sx={{ width: '100%' }}>
      <Grid
        container
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(480px, auto))',
        }}
        sx={{ mt: 2, gap: 2, width: '100%', ml: 0 }}
      >
        <Loading backgroundColor="transparent" loading={loading} />
      </Grid>
      <Stack direction="column" p={2} pb={0}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          Dados gerais
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="stretch" p={2} spacing={2}>
        <GeneralItem
          count={generalInfo?.rifsDoneCount}
          label="Auditorias realizadas"
        />
        <GeneralItem count={generalInfo?.auditedUsersCount} label="Auditados" />
        <GeneralItem count={generalInfo?.processesCount} label="Processos" />
        <GeneralItem
          count={generalInfo?.percentageOfWeaknessesIdentified?.toFixed(2)}
          label="% Fraquezas"
        />
        <GeneralItem
          count={generalInfo?.percentageOfUsersAudited?.toFixed(2)}
          label="% Auditados"
        />
        <GeneralItem
          count={generalInfo?.percentageOfProcessesAudited?.toFixed(2)}
          label="% Processos"
        />
      </Stack>
    </Paper>
  );
};

export default GeneralInfoSection;
