import { CircularProgress, Collapse, Paper } from '@mui/material';

interface ILoading {
  loading: boolean;
  backgroundColor?: string;
}

const Loading: React.FC<ILoading> = ({
  loading,
  backgroundColor = 'grey.100',
}) => (
  <Collapse in={loading} unmountOnExit>
    <Paper
      elevation={0}
      sx={{ bgcolor: backgroundColor, p: 4, textAlign: 'center' }}
    >
      <CircularProgress />
    </Paper>
  </Collapse>
);

export default Loading;
