import { useSearchParams } from 'react-router-dom';
import { TextFieldElement } from 'react-hook-form-mui';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback } from 'react';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';

interface ICancelReasonFilterForm {
  onSubmit(values: Record<string, any>): Promise<void>;
}

const CancelReasonFilterForm: React.FC<ICancelReasonFilterForm> = ({
  onSubmit,
}) => {
  const [searchParams] = useSearchParams();

  const form = useForm({
    defaultValues: Object.fromEntries(searchParams),
  });

  const clearForm = useCallback(() => {
    form.reset();
    setTimeout(() => {
      form.handleSubmit(onSubmit)();
    }, 0);
  }, [form, onSubmit]);

  return (
    <Box sx={{ p: 2, minWidth: '50vw' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography variant="caption">Filtros</Typography>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextFieldElement label="Nome" name="name" />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
              <Button onClick={clearForm}>Limpar</Button>
              <Button color="secondary" type="submit" variant="contained">
                Aplicar
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CancelReasonFilterForm;
