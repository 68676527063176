import {
  DatePickerElement,
  SelectElement,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useEffect, useState } from 'react';

import { FormLabel, Grid, Paper } from '@mui/material';
import Http from '@/services/http';
import { IBase } from '@/models/base-model';

const ServiceForm: React.FC = () => {
  const [bases, setBases] = useState<IBase[]>([]);

  useEffect(() => {
    Http.get('bases', {
      params: {
        pagination: false,
      },
    }).then(({ status, data }) => {
      if (status === 200) setBases(data.docs || []);
    });
  }, []);

  return (
    <>
      <FormLabel sx={{ mb: 1, display: 'block' }}>
        <strong>Dados do serviço</strong>
      </FormLabel>
      <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.100' }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <SwitchElement defaultChecked label="Ativo" name="active" />
          </Grid>
          <Grid item md={12}>
            <TextFieldElement disabled label="Código" name="code" />
          </Grid>
          <Grid item md={12}>
            <TextFieldElement label="Serviço" name="inspection" required />
          </Grid>
          <Grid item md={12}>
            <SelectElement
              label="Base"
              name="base"
              options={bases.map((base) => ({
                id: base._id,
                label: base.name,
              }))}
              required
            />
          </Grid>
          <Grid item md={12}>
            <DatePickerElement
              label="Previsão para execução"
              name="executePrediction"
              required
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ServiceForm;
