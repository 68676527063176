import { useSearchParams } from 'react-router-dom';
import {
  AutocompleteElement,
  DatePickerElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback, useMemo, useState } from 'react';

import { unionBy } from 'lodash';
import { endOfDay, startOfDay } from 'date-fns';
import { Box, Button, debounce, Grid, Stack, Typography } from '@mui/material';
import { statusRifs } from '@/structs/status-rifs';
import { complexities } from '@/structs/complexities';
import Http from '@/services/http';
import { getCleanValues } from '@/helpers/get-clean-values';

interface IServiceFilterForm {
  defaultBases: Option[];
  defaultAircrafts: Option[];
  defaultValues?: Record<string, any>;
  onSubmit(values: Record<string, any>): Promise<void>;
  loading?: boolean;
  type?: 'maintenance' | 'support';
}

interface Option {
  id: string;
  label: string;
}

interface Options {
  bases: Option[];
  aircrafts: Option[];
}

const ServiceFilterForm: React.FC<IServiceFilterForm> = ({
  onSubmit,
  defaultValues,
  defaultBases,
  defaultAircrafts,
  loading = false,
  type = 'maintenance',
}) => {
  const [searchParams] = useSearchParams();

  const form = useForm({
    defaultValues: {
      ...Object.fromEntries(searchParams),
      ...defaultValues,
    },
  });
  const [options, setOptions] = useState<Options>({
    bases: defaultBases ?? [],
    aircrafts: defaultAircrafts ?? [],
  });

  const [isLoadingItems, setIsLoadingItems] = useState(loading);

  const cleanForm = useMemo(() => getCleanValues(form.getValues()), [form]);

  const clearForm = useCallback(() => {
    form.reset(cleanForm);
    setTimeout(() => {
      form.handleSubmit(onSubmit)();
    }, 0);
  }, [form, cleanForm, onSubmit]);

  const fetchBase = useCallback(async (search?: string | null) => {
    setIsLoadingItems(true);
    const { status, data } = await Http.get('bases', {
      params: {
        ...(search ? { search } : {}),
      },
    });

    if (status === 200) {
      setOptions((old) => ({
        ...old,
        bases: unionBy(
          [
            ...old.bases,
            ...data.docs.map((b: any) => ({
              id: b._id,
              label: `${b.name} (${b.code})`,
            })),
          ],
          ({ id }) => id
        ),
      }));
    }
    setIsLoadingItems(false);
  }, []);

  const fetchAircrafts = useCallback(async (search?: string | null) => {
    setIsLoadingItems(true);
    const { status, data } = await Http.get('aircrafts', {
      params: {
        ...(search ? { search } : {}),
      },
    });

    if (status === 200) {
      setOptions((old) => ({
        ...old,
        aircrafts: unionBy(
          [
            ...old.aircrafts,
            ...data.docs.map((b: any) => ({
              id: b._id,
              label: `${b.prefix} (${b._model?.model ?? 'Sem Modelo'})`,
            })),
          ],
          ({ id }) => id
        ),
      }));
    }
    setIsLoadingItems(false);
  }, []);

  return (
    <Box sx={{ p: 2, width: '70vw' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography variant="caption">Filtros</Typography>
          <Stack direction="column" spacing={2}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <TextFieldElement label="Código" name="code" />
              </Grid>
              <Grid item md={3}>
                <AutocompleteElement
                  autocompleteProps={{
                    ChipProps: { size: 'small' },
                    noOptionsText: isLoadingItems
                      ? 'Carregando...'
                      : 'Nenhuma opção',
                    onOpen() {
                      if (options.bases.length === 0) {
                        fetchBase();
                      }
                    },
                    onInputChange: debounce((_, value, reason) => {
                      if (reason === 'input') {
                        fetchBase(value);
                      }
                    }, 300),
                  }}
                  label="Base"
                  matchId
                  name="base"
                  options={options.bases}
                />
              </Grid>
              {type !== 'support' && (
                <Grid item md={6}>
                  <AutocompleteElement
                    autocompleteProps={{
                      ChipProps: { size: 'small' },
                      noOptionsText: isLoadingItems
                        ? 'Carregando...'
                        : 'Nenhuma opção',
                      onOpen: () => {
                        if (options.aircrafts.length === 0) {
                          fetchAircrafts();
                        }
                      },
                      onInputChange: debounce((_, value, reason) => {
                        if (reason === 'input') {
                          fetchAircrafts(value);
                        }
                      }, 300),
                    }}
                    label="Aeronave"
                    matchId
                    name="aircraft"
                    options={options.aircrafts}
                  />
                </Grid>
              )}
              <Grid item md={6}>
                <DatePickerElement
                  label="Previsão de Execução (de)"
                  name="executePredictionStartDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue(
                        'executePredictionStartDate',
                        startOfDay(value)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <DatePickerElement
                  label="Previsão de Execução (até)"
                  name="executePredictionEndDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue(
                        'executePredictionEndDate',
                        endOfDay(value)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <DatePickerElement
                  label="Início do Serviço (de)"
                  name="startRifStartDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('startRifStartDate', startOfDay(value));
                    }
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <DatePickerElement
                  label="Início do Serviço (até)"
                  name="startRifEndDate"
                  onChange={(value, inputValue) => {
                    const shouldParse =
                      (value && !inputValue) || inputValue?.length === 10;
                    if (shouldParse) {
                      form.setValue('startRifEndDate', endOfDay(value));
                    }
                  }}
                />
              </Grid>
              {type !== 'support' && (
                <Grid item md={3}>
                  <AutocompleteElement
                    label="Complexidade"
                    matchId
                    name="complexity"
                    options={complexities}
                  />
                </Grid>
              )}
              <Grid item md={3}>
                <AutocompleteElement
                  label="Status RIF"
                  matchId
                  name="status"
                  options={statusRifs}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
              <Button onClick={clearForm}>Limpar</Button>
              <Button color="secondary" type="submit" variant="contained">
                Aplicar
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ServiceFilterForm;
