import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Grow, Paper, Stack } from '@mui/material';
import Http from '@/services/http';
import Validator from '@/libraries/validator';
import { useAuth } from '@/contexts/auth';
import logo from '@/assets/omni-black.svg';
import bgResetPassword from '@/assets/bg-login.jpg';

interface IResetPassword {
  new_password: string;
}

const validationSchema = Validator.object().shape({
  new_password: Validator.string().required().label('Senha'),
  new_password_confirmation: Validator.string().oneOf(
    [Validator.ref('new_password'), null],
    'Passwords must match'
  ),
});

const ResetPassword: React.FC = () => {
  const auth = useAuth();
  const { token } = useParams();

  const form = useForm<IResetPassword>({
    resolver: Validator.adapter(validationSchema),
  });

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const resetPassword = useCallback(
    async ({ new_password }: IResetPassword) => {
      const response = await Http.post('auth/reset-password', {
        token,
        password: new_password,
      });
      if (response.status === 200) {
        enqueueSnackbar('Senha redefinida com sucesso!', {
          variant: 'success',
        });
        auth.changeUserInfo(response.data);
        navigate('/');
      } else
        enqueueSnackbar('Ocorreu um erro ao tentar redefinir a senha!', {
          variant: 'error',
        });
    },
    [token, enqueueSnackbar, auth, navigate]
  );
  const handleGoBack = useCallback(() => navigate('/auth/login'), [navigate]);

  if (auth.authenticated) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.primary.main,
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundImage: `url(${bgResetPassword})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          minWidth: '100vw',
          backdropFilter: 'blur(20px)',
          flexDirection: 'column',
        }}
      >
        <FormContainer
          defaultValues={{ new_password: '' }}
          formContext={form}
          onSuccess={resetPassword}
        >
          <Stack spacing={3}>
            <Grow in>
              <Paper
                elevation={24}
                sx={{
                  minWidth: { xs: '100%', md: 350 },
                  p: 4,
                }}
              >
                <Stack spacing={4}>
                  <img alt="Omni Brasil" height={50} src={logo} />
                  <TextFieldElement
                    label="Nova senha"
                    name="new_password"
                    required
                    type="password"
                  />
                  <TextFieldElement
                    label="Confirmação de senha"
                    name="new_password_confirmation"
                    required
                    type="password"
                  />
                  <Stack direction="row" spacing={2}>
                    <Button onClick={handleGoBack}>Voltar</Button>
                    <Button
                      color="secondary"
                      disableElevation
                      size="large"
                      sx={{ alignSelf: 'flex-end' }}
                      type="submit"
                      variant="contained"
                    >
                      Redefinir senha
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Grow>
          </Stack>
        </FormContainer>
      </Box>
    </Box>
  );
};

export default ResetPassword;
