import { Navigate, useNavigate } from 'react-router-dom';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Box, Button, Grow, Paper, Stack } from '@mui/material';
import Validator from '@/libraries/validator';
import { useAuth } from '@/contexts/auth';
import logo from '@/assets/omni-black.svg';
import bgLogin from '@/assets/bg-login.jpg';

interface ILogin {
  username: string;
  password: string;
}

const validationSchema = Validator.object().shape({
  username: Validator.string().required().label('Usuário'),
  password: Validator.string().required().label('Senha'),
});

const Login: React.FC = () => {
  const auth = useAuth();

  const form = useForm<ILogin>({
    resolver: Validator.adapter(validationSchema),
  });

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const login = useCallback(
    async ({ username, password }: ILogin) => {
      const response = await auth.login(username, password);
      if (response.authenticated) {
        enqueueSnackbar('Autenticado com sucesso!', { variant: 'success' });
        navigate('/');
      } else
        enqueueSnackbar(
          response.code === 'USER_BLOCKED'
            ? 'Usuário desativado.'
            : 'Usuário e/ou senha incorreto(s)!',
          { variant: 'error' }
        );
    },
    [auth, navigate, enqueueSnackbar]
  );

  const navigateForgetPassword = useCallback(() => {
    navigate('/auth/forget-password');
  }, [navigate]);

  if (auth.authenticated) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.primary.main,
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundImage: `url(${bgLogin})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          minWidth: '100vw',
          backdropFilter: 'blur(20px)',
          flexDirection: 'column',
        }}
      >
        <FormContainer
          defaultValues={{ username: undefined, password: undefined }}
          formContext={form}
          onSuccess={login}
        >
          <Stack spacing={3}>
            <Grow in>
              <Paper
                elevation={24}
                sx={{
                  minWidth: { xs: '100%', md: 350 },
                  p: 4,
                }}
              >
                <Stack spacing={4}>
                  <img alt="Omni Brasil" height={50} src={logo} />
                  <TextFieldElement label="Usuário" name="username" required />
                  <TextFieldElement
                    label="Senha"
                    name="password"
                    required
                    type="password"
                  />
                  <Stack direction="row" spacing={2}>
                    <Button onClick={navigateForgetPassword}>
                      Esqueci minha senha
                    </Button>
                    <Button
                      color="secondary"
                      disableElevation
                      size="large"
                      sx={{ alignSelf: 'flex-end' }}
                      type="submit"
                      variant="contained"
                    >
                      Acessar
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Grow>
          </Stack>
        </FormContainer>
      </Box>
    </Box>
  );
};

export default Login;
